export const config = {
  isLocalRun: import.meta.env.VITE_APP_LOCAL_RUN === 'true',
  version: import.meta.env.VITE_APP_VERSION,
  environment: import.meta.env.MODE,
  appBackendUrlRoot: import.meta.env.VITE_APP_BACKEND_URL_ROOT,
  appWebsocketUrlRoot: import.meta.env.VITE_APP_WEBSOCKET_URL_ROOT,

  keycloak: {
    realm: import.meta.env.VITE_APP_KEYCLOAK_REALM,
    serverUrl: import.meta.env.VITE_APP_KEYCLOAK_SERVER_URL,
    clientId: import.meta.env.VITE_APP_KEYCLOAK_CLIENT_ID,
  },

  gtm: {
    gtmId: import.meta.env.VITE_APP_GTM_ID,
    auth: import.meta.env.VITE_APP_GTM_AUTH,
    preview: import.meta.env.VITE_APP_GTM_PREVIEW,
  },

  logRocket: {
    appId: import.meta.env.VITE_APP_LOGROCKET_APP_ID,
    enabled: import.meta.env.VITE_APP_LOGROCKET_ENABLED !== 'false',
  },

  chameleon: {
    enabled: import.meta.env.VITE_APP_CHAMELEON_ENABLED !== 'false',
    fastUrl: import.meta.env.VITE_APP_CHAMELEON_URL,
    apiKey: import.meta.env.VITE_APP_CHAMELEON_API_KEY,
  },

  chargebee: {
    enabled: import.meta.env.VITE_APP_CHARGEBEE_ENABLED !== 'false',
    site: import.meta.env.VITE_APP_CHARGEBEE_SITE,
  },
};
