import { Navigate, Route, Routes } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import FeatureFlags from '@/models/FeatureFlags';
import RoutePaths from '@/models/RoutePaths';

import BlockDetails from '@/pages/BlockDetails';
import DnsDetails from '@/pages/DnsDetails';
import EventDetails from '@/pages/EventDetails';
import FlowDetails from '@/pages/FlowDetails';
import IpExplorer from '@/pages/IpExplorer';
import IpIntelligence from '@/pages/IpIntelligence';
import NotFound404 from '@/pages/NotFound404';
import RealTimeFlowMap from '@/pages/RealTimeFlowMap';
import RealTimeTraffic from '@/pages/RealTimeTraffic';
import TrafficMiner from '@/pages/TrafficMiner';
import TrafficTop from '@/pages/TrafficTop';

import { CrumbRoute } from '+components/Breadcrumb';

const InvestigateRoutes = () => {
  const isDnsEnabled = useFlag(FeatureFlags.dns);
  const isRealTimeFlowMapEnabled = useFlag(FeatureFlags.showRealTimeFlowMap);

  return (
    <Routes>
      <Route path={`${RoutePaths.search.pageName}/*`}>
        <Route
          index
          element={
            <CrumbRoute
              title={isDnsEnabled ? 'Real-Time Traffic' : 'Real-Time Flow'}
              component={RealTimeTraffic}
            />
          }
        />
        <Route
          path={`${RoutePaths.searchFlow.pageName}/:id`}
          element={<FlowDetails />}
        />
        {isDnsEnabled && (
          <Route
            path={`${RoutePaths.searchDns.pageName}/:id`}
            element={<DnsDetails />}
          />
        )}
        <Route
          path={`${RoutePaths.searchEvents.pageName}/:id`}
          element={<EventDetails />}
        />
        <Route
          path={`${RoutePaths.searchBlocks.pageName}/:id`}
          element={<BlockDetails />}
        />
        <Route
          path="*"
          element={<Navigate to={`${RoutePaths.search}`} replace />}
        />
      </Route>

      <Route
        path={
          isDnsEnabled
            ? RoutePaths.realTimeTraffic.pageName
            : RoutePaths.realTimeFlow.pageName
        }
        element={<Navigate to={`${RoutePaths.search}`} replace />}
      />

      <Route
        path={RoutePaths.trafficMiner.pageName}
        element={<CrumbRoute title="Traffic Miner" component={TrafficMiner} />}
      />

      <Route
        path={
          isDnsEnabled
            ? RoutePaths.trafficTop.pageName
            : RoutePaths.networkTop.pageName
        }
        element={
          <CrumbRoute
            title={isDnsEnabled ? 'Traffic Top' : 'Network Top'}
            component={TrafficTop}
          />
        }
      />

      <Route
        path={RoutePaths.ipExplorer.pageName}
        element={<CrumbRoute title="IP Explorer" component={IpExplorer} />}
      />

      {isRealTimeFlowMapEnabled && (
        <Route
          path={RoutePaths.realTimeFlowMap.pageName}
          element={
            <CrumbRoute
              title="Real-Time Flow Map"
              component={RealTimeFlowMap}
            />
          }
        />
      )}

      <Route
        path={RoutePaths.ipIntelligence.pageName}
        element={
          <CrumbRoute title="IP Intelligence" component={IpIntelligence} />
        }
      />

      <Route
        path="*"
        element={<CrumbRoute title="Page Not Found" component={NotFound404} />}
      />
    </Routes>
  );
};

export default InvestigateRoutes;
