import PropTypes from '+prop-types';
import { Fragment } from 'react';

import AwsAuthTypes from '@/models/AwsAuthTypes';

import ButtonGroupField from '+components/form/ButtonGroupField';
import { Field, useFormState } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const AwsKinesisFields = ({ disabled }) => {
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const awsRegionOptions = useProviderRegions('aws');
  return (
    <Fragment>
      <Field
        name="region"
        label="Region"
        component={SelectField}
        validate={validateRequired}
        options={awsRegionOptions}
        parse={normalizeSelectValue}
        helperText="Location of the source"
        disabled={disabled}
        required
      />
      <Field
        name="stream"
        label="Stream"
        helperText="Data stream name"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        required
      />
      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="roleAuth"
          label="Authentication Type"
          component={ButtonGroupField}
          options={Object.values(AwsAuthTypes)}
          disabled={disabled}
          defaultValue={AwsAuthTypes.role.value}
        />
        {formValues.roleAuth === AwsAuthTypes.key.value ? (
          <Fragment>
            <Field
              name="accesskeyid"
              label="Access Key ID"
              component={TextField}
              type="text"
              validate={validateRequired}
              helperText="The access key id for authenticating to this stream"
              disabled={disabled}
              required
            />

            <Field
              name="accesssecret"
              label="Access Secret"
              component={TextField}
              type="password"
              autoComplete="new-password"
              validate={validateRequired}
              helperText="The access secret for authenticating to this stream"
              disabled={disabled}
              required
            />
          </Fragment>
        ) : (
          <Field
            name="role.arn"
            label="AWS ARN"
            component={TextField}
            type="text"
            validate={validateRequired}
            helperText="The role ARN for authenticating to this stream"
            disabled={disabled}
            required
          />
        )}
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

AwsKinesisFields.propTypes = {
  disabled: PropTypes.bool,
};

AwsKinesisFields.defaultProps = {
  disabled: false,
};

export default AwsKinesisFields;
