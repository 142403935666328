import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, MemoryRouter, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-use';

import { actions as globalFiltersActions } from '@/redux/globalFilters';

import authClient from '@/middleware/authClient';
import MainWrapper from '@/pages/App/MainWrapper';
import { PreloadState } from '@/routers/components/PreloadState';

import { homeUrl } from '+utils';
import loader from '+utils/loader';

import WrappedRoutes from './WrappedRoutes';

const GuestMode = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { params: { guestCode } = {} } =
    matchPath('/g/:guestCode', pathname) || {};

  const [realGuestUrl, setRealGuestUrl] = useState(null);
  const initialEntries = useMemo(() => [realGuestUrl], [realGuestUrl]);

  useEffect(() => {
    if (realGuestUrl) {
      return;
    }

    (async () => {
      try {
        loader.setMessage('Loading guest data... 40%').show();
        const {
          data: { data: meta },
        } = await authClient.guestLogin(guestCode);

        const { token, data } = meta;

        authClient.setGuest({
          code: guestCode,
          token,
          data,
        });

        loader.setMessage('Loading guest data... 60%');

        if (!data.url) {
          throw new Error('No url in guest data');
        }

        setRealGuestUrl(data.url);

        if (data.globalFilters) {
          dispatch(globalFiltersActions.changeFilter(data.globalFilters));
        }
      } catch (errr) {
        window.location.href = homeUrl;
      }
    })();
  }, [guestCode, realGuestUrl]);

  const demo = realGuestUrl === '/';

  const hideNav = !demo;

  return !realGuestUrl ? null : (
    <MemoryRouter initialEntries={initialEntries}>
      <MainWrapper>
        <Routes>
          <Route
            path="*"
            element={
              <PreloadState>
                <WrappedRoutes guest demo={demo} hideNav={hideNav} />
              </PreloadState>
            }
          />
        </Routes>
      </MainWrapper>
    </MemoryRouter>
  );
};

export default GuestMode;
