/* eslint-disable camelcase, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus, jsx-a11y/no-static-element-interactions  */
import { useMemo } from 'react';

import styled from 'styled-components';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import AlphaECircleIcon from 'mdi-react/AlphaECircleIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import HomeGroupIcon from 'mdi-react/HomeGroupIcon';
import HomeIcon from 'mdi-react/HomeIcon';
import PinIcon from 'mdi-react/PinIcon';
import StarIcon from 'mdi-react/StarIcon';

import GenericLabel from '+components/Labels/GenericLabel';
import { ShowMoreWrapper } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  RowSelectorColumnFactory,
  StringsArrayColumnsFactory,
  TimestampColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  FilterOperator,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import { autoRemoveByOperator } from '+components/Table/ReactTable/utils';
import Tag from '+components/Tag';
import Tooltip from '+components/Tooltip';
import { getColumnsHelper, makeId } from '+utils';
import dayjs, { DateFormat } from '+utils/dayjs';

const cellWithGap = {
  display: 'flex',
  gap: '0.25rem',
  flexWrap: 'wrap',
};

const Title = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  .link {
    color: ${({ theme }) => theme.linkColor} !important;
    cursor: pointer;
  }
  .icon {
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.colorTextSecondary};
  }
  .link + .icon {
    margin-left: 5px;
  }
`;

const User = styled.div`
  color: ${({ theme }) => theme.linkColor} !important;
  cursor: pointer;
`;

const dateTimeCellFilter = withAutoRemove((rows, [id], filterValue) => {
  if (autoRemoveIfAll(filterValue)) {
    return rows;
  }

  return rows.filter(({ values: { [id]: value } }) => {
    if (filterValue.value === 'custom') {
      return !value;
    }
    return value;
  });
}, autoRemoveIfAll);

const nqlModeCellFilter = withAutoRemove((rows, [id], filterValue) => {
  if (autoRemoveIfAll(filterValue)) {
    return rows;
  }

  return rows.filter(({ original, values: { [id]: value } }) => {
    const normalizedValue =
      value || (original.useGlobalDateTime ? 'global' : 'override');
    return normalizedValue === filterValue.value;
  });
}, autoRemoveIfAll);

const sortAuthorColumn = (rowA, rowB) => {
  const originalA = getRowOriginal(rowA);
  const originalB = getRowOriginal(rowB);
  const valueA = originalA?.user?.name || originalA?.created_by || '';
  const valueB = originalB?.user?.name || originalB?.created_by || '';
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

export const Columns = {
  rowSelector: 'rowSelector',
  groups: 'groups',
  title: 'title',
  description: 'description',
  author: 'author',
  useGlobalDateTime: 'useGlobalDateTime',
  nqlMode: 'nqlMode',
  lastseen: 'lastseen',
  menu: 'menu',
};

const columnsCollection = ({
  profile,
  companyHomepageDashboard,
  userHomepageDashboard,
  eventTemplateDashboard,
  cxActionMenu,
  cxGroupActionMenu,
  onTitleClick,
  onUserClick,
}) => ({
  [Columns.rowSelector]: RowSelectorColumnFactory(),
  [Columns.groups]: StringsArrayColumnsFactory({
    accessor: 'groups',
    Header: 'Collection',
    width: 260,
    Cell: ({ value }) =>
      useMemo(
        () =>
          !value.length ? null : (
            <ShowMoreWrapper>
              {value.map((val) => (
                <GenericLabel
                  key={`${val}_${makeId()}`}
                  field=""
                  value={val}
                  clickable={false}
                  context={val}
                />
              ))}
            </ShowMoreWrapper>
          ),
        [value],
      ),
  }),
  [Columns.title]: BaseColumnFactory({
    accessor: 'title',
    Header: 'Dashboard',
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => {
      const original = getRowOriginal(row);
      return (
        <Title>
          <div className="link" onClick={() => onTitleClick(original)}>
            {original.title}
          </div>
          {original.pinned && (
            <Tooltip title="Pinned" arrow={false}>
              <span className="icon">
                <PinIcon size={18} />
              </span>
            </Tooltip>
          )}
          {original.favorite && (
            <Tooltip title="Favorite" arrow={false}>
              <span className="icon">
                <StarIcon size={18} />
              </span>
            </Tooltip>
          )}
          {original.id === userHomepageDashboard && (
            <Tooltip title="Your Homepage" arrow={false}>
              <span className="icon">
                <HomeIcon size={18} />
              </span>
            </Tooltip>
          )}
          {original.id === companyHomepageDashboard && (
            <Tooltip title="Company Homepage" arrow={false}>
              <span className="icon">
                <HomeGroupIcon size={18} />
              </span>
            </Tooltip>
          )}
          {original.id === eventTemplateDashboard && (
            <Tooltip title="Event Template Page" arrow={false}>
              <span className="icon">
                <AlphaECircleIcon size={18} />
              </span>
            </Tooltip>
          )}
          {original.hidden && (
            <Tooltip title="Hidden Dashboard" arrow={false}>
              <span className="icon">
                <EyeOffIcon size={18} />
              </span>
            </Tooltip>
          )}
          {original.public && (
            <Tooltip title="Public Dashboard" arrow={false}>
              <span className="icon">
                <AccountMultipleIcon size={16} />
              </span>
            </Tooltip>
          )}
        </Title>
      );
    },
    Filter: SelectColumnFilter({
      fixedOptions: [
        'all',
        'pinned',
        'favorite',
        'homepage',
        'template',
        'public',
        'private',
      ].sort(),
      enableLikeFilter: true,
    }),
    filter: withAutoRemove((rows, _, filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'pinned'
      ) {
        return rows.filter(({ original }) => original.pinned);
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'favorite'
      ) {
        return rows.filter(({ original }) => original.favorite);
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'homepage'
      ) {
        return rows.filter(({ original }) =>
          [userHomepageDashboard, companyHomepageDashboard].includes(
            original.id,
          ),
        );
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'template'
      ) {
        return rows.filter(
          ({ original }) => original.id === eventTemplateDashboard,
        );
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'public'
      ) {
        return rows.filter(({ original }) => original.public);
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'private'
      ) {
        return rows.filter(({ original }) => !original.public);
      }

      return rows.filter(({ original }) => {
        const { title } = original;
        const normalizedFilterValue = String(filterValue.value).toLowerCase();
        return String(title).toLowerCase().includes(normalizedFilterValue);
      });
    }, autoRemoveIfAll),
  }),
  [Columns.description]: BaseColumnFactory({
    accessor: 'description',
    Header: 'Description',
  }),
  [Columns.author]: BaseColumnFactory({
    accessor: 'created_by',
    Header: 'Author',
    width: 220,
    Cell: ({ row }) =>
      useMemo(() => {
        const original = getRowOriginal(row);
        return !original?.user ? (
          original?.created_by
        ) : (
          <User role="button" onClick={() => onUserClick(original)}>
            {original?.user?.name}
          </User>
        );
      }, [row]),
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'Mine', 'Not Mine'],
      enableLikeFilter: true,
    }),
    filter: withAutoRemove((rows, _, filterValue) => {
      if (autoRemoveByOperator(filterValue)) {
        return rows;
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'Mine'
      ) {
        return rows.filter(
          ({ original }) =>
            !original.system && original.created_by === profile.email,
        );
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'Not Mine'
      ) {
        return rows.filter(
          ({ original }) =>
            original.system || original.created_by !== profile.email,
        );
      }

      const normalizedFilterValue = String(filterValue.value).toLowerCase();
      return rows.filter(
        ({ original }) =>
          !original.system &&
          (original.created_by.toLowerCase().includes(normalizedFilterValue) ||
            original.user?.name?.toLowerCase().includes(normalizedFilterValue)),
      );
    }, autoRemoveByOperator),
    sortType: sortAuthorColumn,
  }),
  [Columns.useGlobalDateTime]: BaseColumnFactory({
    accessor: 'useGlobalDateTime',
    Header: 'Date/Time',
    width: 100,
    getCellProps: () => ({
      style: { ...cellWithGap, justifyContent: 'center' },
    }),
    Cell: ({ value }) =>
      useMemo(
        () => (
          <Tag color="info" outlined={false}>
            {value ? 'Global' : 'Custom'}
          </Tag>
        ),
        [value],
      ),
    Filter: SelectColumnFilter({ fixedOptions: ['all', 'global', 'custom'] }),
    filter: dateTimeCellFilter,
  }),
  [Columns.nqlMode]: BaseColumnFactory({
    accessor: 'nqlMode',
    Header: 'NQL',
    width: 100,
    getCellProps: () => ({
      style: {
        textTransform: 'uppercase',
        ...cellWithGap,
        justifyContent: 'center',
      },
    }),
    Cell: ({
      value,
      cell: {
        row: {
          original: { useGlobalDateTime },
        },
      },
    }) =>
      useMemo(
        () => (
          <Tag color="info" outlined={false}>
            {value || (useGlobalDateTime ? 'global' : 'override')}
          </Tag>
        ),
        [value, useGlobalDateTime],
      ),
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'global', 'override', 'append'],
    }),
    filter: nqlModeCellFilter,
  }),
  [Columns.lastseen]: TimestampColumnFactory({
    accessor: 'lastseen',
    Header: 'Last Seen',
    width: 160,
    Cell: ({ value }) =>
      value ? dayjs(value).format(DateFormat.minute) : 'Never',
  }),
  [Columns.menu]: MenuColumnFactory({ cxActionMenu, cxGroupActionMenu }),
});

export const getColumns = getColumnsHelper(columnsCollection);
