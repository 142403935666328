import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';
import cloneDeep from 'lodash.clonedeep';

import { ContextTypes } from '@/models/ContextTypes';
import FeatureFlags from '@/models/FeatureFlags';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';
import {
  actions as thresholdActions,
  selectors as thresholdSelectors,
} from '@/redux/api/thresholder';

import CommonAdd from '../shared/CommonAdd';
import {
  cadenceOptions,
  Config,
  strategyOptions,
  uiToAutoThresholdParams,
  uiToParams,
} from '../shared/utils';
import FormBody from './FormBody';

export const autoThresholdDefaults = {
  strategy: strategyOptions.average,
  data_interval: cadenceOptions.daily,
  data_window: 1,
  data_lookback: 60,
  force_override: false,
  low_sigma: 1,
  med_sigma: 2,
  high_sigma: 3,
};

const commonDefaultValues = {
  algo_type: 'TDM',
  algo_record_type: ContextTypes.flow,
  enabled: true,
  bypassdisplay: false,
  bypassrule: false,
  rollupperiod: 300,
  updateinterval: 0,
  subscriptiontype: 'optout',
  ndm_score_threat: 0,
  ndm_score_confidence: 0,
};

const preparedDefaultValues = {
  [ContextTypes.flow]: {
    ...commonDefaultValues,
    search_by: [Config.defaultSearchBy],
    thresholds: [Config.defaultThresholds],
    track_by: [Config.defaultTrackBy],
    discards: [Config.defaultDiscards],
  },
  [ContextTypes.dns]: {
    ...commonDefaultValues,
    search_by: [Config.defaultSearchBy],
    thresholds: [Config.defaultThresholds],
    track_by: [Config.defaultTrackBy],
    discards: [Config.defaultDiscards],
  },
};

const pickFields = [
  'name',
  'algo_record_type',
  'description',
  'categories',
  'enabled',
  'search_by',
  'rollupperiod',
  'thresholds',
  'track_by',
  'discards',
  'updateinterval',
  'ndm_score_confidence',
  'ndm_score_threat',
];

const AlgorithmAdd = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const initId = searchParams.get('init');

  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const initAlgorithm = useSelector(rulesSelectors.getAlgorithm(initId));
  const initAutomaton = useSelector(
    thresholdSelectors.getAutomaton(initAlgorithm?.name),
  );
  const addedAlgorithm = useSelector(rulesSelectors.getAddedAlgorithm);
  const isFetching = useSelector(thresholdSelectors.isFetching);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [creationValues, setCreationValues] = useState({});

  const onSubmit = useCallback(({ id, ...values }) => {
    setCreationValues(values);
    dispatch(
      rulesActions.addAlgorithm({
        ...uiToParams(values),
        saveAddedAlgorithm: values.autoThresholdEnabled,
        silent: values.autoThresholdEnabled,
      }),
    );
  }, []);

  const defaultValues = useMemo(() => {
    const result = cloneDeep(preparedDefaultValues);
    Object.entries(result).forEach(([key, value]) => {
      result[key] = {
        ...value,
        // if DM has an auto threshold config, use those values, else use defaults.
        autoThresholdEnabled: !!initAutomaton,
        autoThresholdData: {
          ...(initAutomaton ?? autoThresholdDefaults),
          data_lookback:
            initAutomaton?.data_lookback ??
            customer?.retention ??
            autoThresholdDefaults.data_lookback,
        },
      };
    });
    return result;
  }, [customer?.retention, initAutomaton]);

  useEffect(() => {
    if (initId && !initAlgorithm) {
      dispatch(rulesActions.fetchAlgorithm(initId));
    }
    if (initId && initAlgorithm?.name) {
      dispatch(
        thresholdActions.fetchAutomaton({
          name: initAlgorithm?.name,
        }),
      );
    }
  }, [initId, initAlgorithm]);

  const postProcessing = useCallback(() => {
    if (isFetching) {
      // we don't want to finish submitting process and close from.
      return false;
    }

    if (
      !isSubmitting &&
      creationValues.autoThresholdEnabled &&
      creationValues?.algo_record_type === ContextTypes.flow &&
      addedAlgorithm
    ) {
      setIsSubmitting(true);
      const automatonConfig = uiToAutoThresholdParams(
        creationValues,
        customer?.shortname,
      );
      dispatch(
        thresholdActions.updateAutomaton({
          ...automatonConfig,
          afterCreateMode: true,
        }),
      );
      return false;
    }

    dispatch(rulesActions.clearAddedAlgorithm());
    return true;
  }, [creationValues, addedAlgorithm, isSubmitting, isFetching]);

  const title = isDnsEnabled ? '' : 'Network ';

  return (
    <CommonAdd
      title={`Add ${title}Detection Model`}
      initAlgorithm={initAlgorithm}
      pickFields={pickFields}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      FormBody={FormBody}
      focusOnFields={false}
      isLoading={isFetching}
      postProcessing={postProcessing}
    />
  );
};

export default AlgorithmAdd;
