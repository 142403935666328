import PropTypes from '+prop-types';
import { Fragment } from 'react';

import AwsAuthTypes from '@/models/AwsAuthTypes';

import ButtonGroupField from '+components/form/ButtonGroupField';
import { useFormState } from '+components/form/FinalForm';
import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const S3Fields = ({ disabled }) => {
  const regionOptions = useProviderRegions('aws');
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });
  return (
    <Fragment>
      <Field
        name="s3.region"
        label="Region"
        component={SelectField}
        validate={validateRequired}
        options={regionOptions}
        parse={normalizeSelectValue}
        helperText="Location of the Amazon Web Services"
        disabled={disabled}
        required
      />
      <Field
        name="s3.bucket"
        label="Bucket Name"
        component={TextField}
        type="text"
        validate={validateRequired}
        helperText="Name of the s3 bucket from which to pull the csv file"
        disabled={disabled}
        required
      />
      <Field
        name="s3.path"
        label="CSV File Path"
        component={TextField}
        type="text"
        validate={validateRequired}
        helperText="Path to the csv file to import labels from. The format of the file is ip,context,label1,label2,..."
        disabled={disabled}
        required
      />
      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="roleAuth"
          label="Authentication Type"
          component={ButtonGroupField}
          options={Object.values(AwsAuthTypes)}
          disabled={disabled}
          defaultValue={AwsAuthTypes.role.value}
        />
        {formValues.roleAuth === AwsAuthTypes.key.value ? (
          <Fragment>
            <Field
              name="s3.accesskeyid"
              label="Access Key ID"
              component={TextField}
              type="text"
              validate={validateRequired}
              helperText="The access key id for authenticating to Amazon Web Services"
              disabled={disabled}
              required
            />

            <Field
              name="s3.accesssecret"
              label="Access Secret"
              component={TextField}
              type="password"
              autoComplete="off"
              validate={validateRequired}
              helperText="The access secret for authenticating to Amazon Web Services"
              disabled={disabled}
              required
            />
          </Fragment>
        ) : (
          <Field
            name="s3.role.arn"
            label="AWS ARN"
            component={TextField}
            type="text"
            validate={validateRequired}
            helperText="The role ARN for authenticating to Amazon Web Services"
            disabled={disabled}
            required
          />
        )}
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

S3Fields.propTypes = {
  disabled: PropTypes.bool,
};

S3Fields.defaultProps = {
  disabled: false,
};

export default S3Fields;
