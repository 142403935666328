/* eslint-disable camelcase */
import PropTypes from '+prop-types';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { selectors as customerSelectors } from '@/redux/api/customer';

import ButtonGroupField from '+components/form/ButtonGroupField';
import FieldsSection from '+components/form/FieldsSection';
import { Field, useFormState } from '+components/form/FinalForm';
import { Label } from '+components/form/FormField';
import { normalizeSelectValue } from '+components/form/Normalizers';
import NumberField from '+components/form/NumberField';
import SelectField from '+components/form/SelectField';
import { SliderField } from '+components/form/Slider';
import { ToggleField } from '+components/form/Toggle';
import ToggleRow from '+components/form/ToggleRow';
import { validateNumber, validateRequired } from '+components/form/Validators';
import { Col } from '+components/Layout';

import {
  formatTimeSliderLabel,
  getSliderMarks,
} from '../../shared/sliderUtils';
import { cadenceOptions, strategyOptions } from '../../shared/utils';

const ThresholdToggleOptions = [
  { value: false, label: 'Disabled' },
  { value: true, label: 'Enabled' },
];

const sigmaValStep = 0.1;

const AutoThresholdsSection = ({ canManage }) => {
  const { values } = useFormState({
    subscription: { values: true },
  });

  const { autoThresholdEnabled = false, autoThresholdData } = values || {};
  const { low_sigma, med_sigma, high_sigma } = autoThresholdData;
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  return (
    <Fragment>
      <Field
        name="autoThresholdEnabled"
        label="Auto Thresholding"
        options={ThresholdToggleOptions}
        component={ButtonGroupField}
        disabled={!canManage}
      />
      {autoThresholdEnabled && (
        <FieldsSection label="AUTO THRESHOLD OPTIONS" boldLabel formLabelMargin>
          <Field
            name="autoThresholdData.strategy"
            label="Strategy"
            component={SelectField}
            options={Object.values(strategyOptions)}
            validate={validateRequired}
            parse={normalizeSelectValue}
            disabled={!canManage}
            helperText="Method by which groups are collected to calculate the global value"
            style={{ maxWidth: '200px' }}
            required
          />
          <Field
            name="autoThresholdData.data_interval"
            label="Cadence"
            component={SelectField}
            options={Object.values(cadenceOptions)}
            validate={validateRequired}
            parse={normalizeSelectValue}
            helperText="Timescale used to calculate auto thresholds"
            disabled={!canManage}
            style={{ maxWidth: '200px' }}
            required
          />
          <Field
            name="autoThresholdData.data_window"
            label="Learning Window"
            component={SliderField}
            min={1}
            max={24}
            isRangeSlider={false}
            showInputField
            marks={getSliderMarks(5, 4)}
            disabled={!canManage}
            helperText="Auto threshold learning window duration"
            inputHelperText="Hours"
            valueLabelFormat={(value) => formatTimeSliderLabel(value * 3600)}
            required
          />
          <Field
            name="autoThresholdData.data_lookback"
            label="Lookback"
            component={SliderField}
            min={7}
            max={180}
            isRangeSlider={false}
            showInputField
            marks={getSliderMarks(5, 30)}
            disabled={!canManage}
            helperText={`The maximum age of data used to train thresholds is ${customer.retention} days. The recommended value is your retention period.`}
            inputHelperText="Days"
            valueLabelFormat={(value) => `${value}d`}
            required
          />
          <FieldsSection
            formLabelMargin
            label="Advanced Auto Thresholds Options"
            collapsible
          >
            <Field
              name="autoThresholdData.force_override"
              label="Force Override"
              helperText="Enable to override the default behavior preventing threshold values below the global average"
              options={ThresholdToggleOptions}
              checkedLabel="Enabled"
              uncheckedLabel="Disabled"
              uppercaseLabels={false}
              component={ToggleField}
              disabled={!canManage}
              type="checkbox"
            />

            <ToggleRow label="Sigma Values" helperText="test test ">
              <Col>
                <SigmaFieldsRow>
                  <Field
                    name="autoThresholdData.low_sigma"
                    label="Low"
                    component={NumberField}
                    step={sigmaValStep}
                    min={0}
                    max={med_sigma - sigmaValStep}
                    precision={1}
                    validate={[validateNumber, validateRequired]}
                    autoComplete="new-password"
                  />
                  <Field
                    name="autoThresholdData.med_sigma"
                    label="Medium"
                    component={NumberField}
                    step={sigmaValStep}
                    min={low_sigma + sigmaValStep}
                    max={high_sigma - sigmaValStep}
                    precision={1}
                    validate={[validateNumber, validateRequired]}
                    autoComplete="new-password"
                  />

                  <Field
                    name="autoThresholdData.high_sigma"
                    label="High"
                    component={NumberField}
                    step={sigmaValStep}
                    min={med_sigma + sigmaValStep}
                    max={9.9}
                    precision={1}
                    validate={[validateNumber, validateRequired]}
                    autoComplete="new-password"
                  />
                </SigmaFieldsRow>
                <SigmaFieldsLabel>
                  {
                    'The number of standard deviations to use when calculating thresholds for each severity. 0 < low < medium < high < 10'
                  }
                </SigmaFieldsLabel>
              </Col>
            </ToggleRow>
          </FieldsSection>
        </FieldsSection>
      )}
    </Fragment>
  );
};

const SigmaFieldsLabel = styled(Label)`
  font-size: 11px !important;
  font-weight: 100 !important;
  width: fit-content !important;
  color: ${(props) => props.theme.colorTextSecondary} !important;
`;

const SigmaFieldsRow = styled.div`
  display: flex;
  flex-direction: row;

  .form__form-group-label {
    width: fit-content !important;
  }
  .form__form-group-field {
    width: 90px !important;
    margin-right: 20px !important;
  }
`;

AutoThresholdsSection.propTypes = {
  canManage: PropTypes.bool,
};

AutoThresholdsSection.defaultProps = {
  canManage: false,
};

export default AutoThresholdsSection;
