import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import { ContextTypes } from '@/models/ContextTypes';
import SettingCategories from '@/models/SettingCategories';
import StatsRequest from '@/models/StatsRequest';

import {
  ConnectionStatus,
  actions as newSocketActions,
  selectors as newSocketSelectors,
} from '@/redux/newsocket';
import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import { timeBounds } from '@/shared/utils';

import { usePageTabs } from '+components/PageTabs';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';

const contextNames = {
  [ContextTypes.alerts]: 'alert',
  [ContextTypes.blocks]: 'block',
  [ContextTypes.dns]: 'dns',
  [ContextTypes.traffic]: 'traffic',
  [ContextTypes.flow]: 'flow',
};

const useSocketOptions = (props) => {
  const {
    context,
    isSocketPaused,
    additionalSocketOptions,
    overrideSocketOptions,
    includeFields,
  } = props;

  const fixedContext = context || ContextTypes.flow;

  const [socketLimit] = usePortalSettingsValue(
    SettingCategories.socket,
    'limit',
    50,
  );
  const [filters] = useGlobalFilters(fixedContext);
  const { start, end } = timeBounds(filters);
  const isRealtime = !isSocketPaused;

  const socketOptions = useMemo(
    () =>
      overrideSocketOptions || {
        ...(!isRealtime && { start, end, size: 1000 }),
        ...(isRealtime && { last: 40 }),
        limit: socketLimit,
        feed: contextNames[fixedContext] || fixedContext,
        return: ['all'],
        include: includeFields || undefined,
        type: 'search',
        format: 'object',
        ...StatsRequest.makeSearch({
          search: filters.nql,
          intersect: filters.intersect,
        }),
        customers: filters.customers?.length ? filters.customers : undefined,
        ...(additionalSocketOptions || {}),
      },
    [
      isRealtime,
      JSON.stringify(overrideSocketOptions),
      start,
      end,
      JSON.stringify(additionalSocketOptions),
      socketLimit,
      JSON.stringify(filters.nql),
      JSON.stringify(filters.intersect),
      JSON.stringify(filters.customers),
      JSON.stringify(includeFields),
    ],
  );

  return {
    socketOptions,
    isRealtime,
  };
};

export const useRealtimeOrRequest = (props) => {
  const {
    name,
    subscribeMode,
    context,
    additionalSocketOptions,
    socketOptions: overrideSocketOptions,
    stopRequest,
    includeFields,
    refresher,
  } = props;

  const dispatch = useDispatch();

  const [windowFocused] = useUIProperty('windowFocused', true);
  const [localWindowFocused, setLocalWindowFocused] = useState(windowFocused);

  const [, activePageTab] = usePageTabs();
  const records = useSelector(newSocketSelectors.recordsSelector(name));
  const socketStatus = useSelector(newSocketSelectors.socketStateSelector);
  const isEventSubscribed = useSelector(newSocketSelectors.isSubscribed(name));
  const isEventSubscribing = useSelector(
    newSocketSelectors.isSubscribing(name),
  );
  const isEventUnsubscribing = useSelector(
    newSocketSelectors.isUnsubscribing(name),
  );
  const isSubscribed = useRef(isEventSubscribed);
  const isRequesting = useSelector(newSocketSelectors.isRequesting(name));
  const isSocketReady = socketStatus === ConnectionStatus.ready;
  const isSocketPaused = useSelector(
    socketControlSelectors.isPaused(activePageTab?.id),
  );
  const wasSubscribed = useRef(isEventSubscribed);

  const isFetching =
    socketStatus !== ConnectionStatus.ready ||
    isRequesting ||
    isEventSubscribing ||
    isEventUnsubscribing;

  const { isRealtime, socketOptions } = useSocketOptions({
    overrideSocketOptions,
    additionalSocketOptions,
    context,
    isSocketPaused,
    includeFields,
  });

  useLoadingIndicator(isFetching);

  // Do not use useDebounce hook here
  // because it will not work when windows is not in focus
  const timer = useRef();
  useEffect(() => {
    timer.current = setTimeout(
      () => {
        setLocalWindowFocused(windowFocused);
      },
      windowFocused ? 1e3 : 1e4,
    );
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [windowFocused]);

  useEffect(() => {
    if (!isSocketReady || isEventUnsubscribing || stopRequest) {
      return;
    }

    const isSocketRequestMode = !!socketOptions.start;
    if (isSocketPaused || isSocketRequestMode || !localWindowFocused) {
      if (isSubscribed.current) {
        dispatch(newSocketActions.unsubscribe({ name }));
        isSubscribed.current = false;
        wasSubscribed.current = true;
      }
      return;
    }

    dispatch(
      newSocketActions.subscribe({
        name,
        options: {
          ...socketOptions,
          ...(isSubscribed.current && { replace: true }),
        },
        ...(subscribeMode ? { mode: subscribeMode } : {}),
      }),
    );

    isSubscribed.current = true;
    wasSubscribed.current = true;
  }, [
    localWindowFocused,
    isEventUnsubscribing,
    isSocketReady,
    isSocketPaused,
    name,
    JSON.stringify(socketOptions),
    stopRequest,
  ]);

  useEffect(() => {
    wasSubscribed.current = false;
  }, [refresher]);

  useDebounce(
    () => {
      if (!isSocketReady || isEventUnsubscribing || stopRequest) {
        return;
      }
      if (wasSubscribed.current) {
        return;
      }
      const isSocketRequestMode = !!socketOptions.start;
      if (isSocketRequestMode) {
        dispatch(newSocketActions.request({ name, options: socketOptions }));
      }
    },
    1000,
    [
      isSocketReady,
      isEventUnsubscribing,
      stopRequest,
      name,
      refresher,
      JSON.stringify(socketOptions),
    ],
  );

  useEffect(
    () => () => {
      if (isSubscribed.current) {
        isSubscribed.current = false;
        wasSubscribed.current = false;
        dispatch(newSocketActions.unsubscribe({ name }));
      } else {
        dispatch(newSocketActions.clearData({ name }));
      }
    },
    [name],
  );

  return {
    isRealtime,
    isFetching,
    isSocketPaused,
    records,
    socketOptions,
  };
};

export default useRealtimeOrRequest;
