import styled, { css } from 'styled-components';

const Tab = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  overflow: hidden;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.sideBarItemHoverBackground};
  }

  ${({ $active }) =>
    $active &&
    css`
      font-weight: bold;
      color: ${({ theme }) => theme.sideBarItemActiveColor} !important;
    `}
`;

export default Tab;
