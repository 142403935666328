import PropTypes from '+prop-types';
import { Fragment } from 'react';

import styled from 'styled-components';

import Box from '@mui/material/Box';

import { CardTitle } from '+components/Card';
import CopyTextOrigin from '+components/CopyText';
import Field from '+components/form/FinalForm/Field';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const CopyText = styled(CopyTextOrigin)`
  .copy-to-clipboard__button {
    margin-left: 6px;
  }
`;

const GcpPubsubFields = ({ disabled }) => (
  <Fragment>
    <Field
      name="projectid"
      label="Project ID"
      component={TextField}
      type="text"
      validate={validateRequired}
      helperText="Your Google Cloud project ID"
      disabled={disabled}
      required
    />

    <Field
      name="subid"
      label="Subscription ID"
      component={TextField}
      type="text"
      validate={validateRequired}
      helperText="Your Google Cloud pub/sub subscription ID"
      disabled={disabled}
      required
    />

    <RoleAuthFieldsSection label="Authentication">
      <CardTitle
        Component={Box}
        head="Netography Service Account"
        subhead={
          <CopyText text="sa-cloud@netography.iam.gserviceaccount.com">
            sa-cloud@netography.iam.gserviceaccount.com
          </CopyText>
        }
        ml="140px"
      />
    </RoleAuthFieldsSection>
  </Fragment>
);

GcpPubsubFields.propTypes = {
  disabled: PropTypes.bool,
};

GcpPubsubFields.defaultProps = {
  disabled: false,
};

export default GcpPubsubFields;
