import PropTypes from '+prop-types';
import { Fragment, useCallback } from 'react';

import {
  Field,
  FieldArray,
  useForm,
  useFormState,
} from '+components/form/FinalForm';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import PhoneNumberField from '+components/form/PhoneNumberField';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

import RenderPhoneInputArray from '../components/RenderPhoneInputArray';

const TwilioFields = ({ disabled }) => {
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const { from, messagingservicesid } = formValues?.config || {};

  const { change } = useForm();

  const onCountryChange = useCallback(
    (value) => change('config.from.region', value),
    [],
  );

  return (
    <Fragment>
      <Field
        name="name"
        label="Name"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        style={{ width: '50%' }}
        disabled={disabled}
        required
      />

      <Field
        name="description"
        label="Description"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        disabled={disabled}
      />

      <Field
        name="config.from.number"
        label="From"
        component={PhoneNumberField}
        defaultCountry={from.region}
        country={from.region}
        onCountryChange={onCountryChange}
        validate={messagingservicesid ? undefined : validateRequired}
        required={!messagingservicesid}
        disabled={disabled || !!messagingservicesid}
      />

      <Field
        name="config.messagingservicesid"
        label="Service SID"
        component={TextField}
        type="text"
        autoComplete="new-password"
        validate={!messagingservicesid ? undefined : validateRequired}
        required={!!messagingservicesid}
        disabled={disabled || !!from.number}
      />

      <Group>
        <Label disabled={disabled}>To</Label>
        <FieldContainer>
          <FieldArray
            name="config.to"
            component={RenderPhoneInputArray}
            disabled={disabled}
          />
        </FieldContainer>
      </Group>
      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="config.accountsid"
          label="Account SID"
          component={TextField}
          type="text"
          autoComplete="new-password"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="config.authtoken"
          label="Auth Token"
          component={TextField}
          type="password"
          autoComplete="new-password"
          validate={validateRequired}
          disabled={disabled}
          required
        />
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

TwilioFields.propTypes = {
  disabled: PropTypes.bool,
};

TwilioFields.defaultProps = {
  disabled: false,
};

export default TwilioFields;
