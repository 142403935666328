import PropTypes from '+prop-types';
import { Fragment } from 'react';

import AwsAuthTypes from '@/models/AwsAuthTypes';

import ButtonGroupField from '+components/form/ButtonGroupField';
import { Field, useFormState } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const AwsTgwFields = ({ disabled, isDnsContext }) => {
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const awsRegionOptions = useProviderRegions('aws');
  return (
    <Fragment>
      <Field
        name="accountid"
        label="Account ID"
        component={TextField}
        type="text"
        validate={validateRequired}
        helperText="Account ID of the source"
        disabled={disabled}
        required
      />
      <Field
        name="region"
        label="Region"
        component={SelectField}
        validate={validateRequired}
        options={awsRegionOptions}
        parse={normalizeSelectValue}
        helperText="Region of the transit gateway"
        disabled={disabled}
        required
      />

      <Field
        name="bucket"
        label="Bucket Name"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        helperText="The s3 bucket name"
        disabled={disabled}
        required
      />

      <Field
        name="bucketregion"
        label="Bucket Region"
        component={SelectField}
        validate={validateRequired}
        options={awsRegionOptions}
        parse={normalizeSelectValue}
        helperText="Region of the s3 bucket"
        disabled={disabled}
        required
      />

      <Field
        name="prefix"
        label="Prefix"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        helperText="Folder prefix"
        disabled={disabled}
      />

      <Field
        name="sqsurl"
        label="sqs URL"
        component={TextField}
        type="url"
        maxLength={255}
        autoComplete="new-password"
        helperText={`
          If provided, sqs will notify Netography that a new object was written for immediate ingest. 
          e.g. https://sqs.us-east-1.amazonaws.com/123456789012/${
            isDnsContext ? 'query' : 'flow'
          }logq
        `}
        disabled={disabled}
      />

      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="roleAuth"
          label="Authentication Type"
          component={ButtonGroupField}
          options={Object.values(AwsAuthTypes)}
          disabled={disabled}
          defaultValue={AwsAuthTypes.role.value}
        />
        {formValues.roleAuth === AwsAuthTypes.key.value ? (
          <Fragment>
            <Field
              name="accesskeyid"
              label="Access Key ID"
              component={TextField}
              type="text"
              validate={validateRequired}
              helperText="The access key for authenticating to this bucket"
              disabled={disabled}
              required
            />

            <Field
              name="accesssecret"
              label="Access Secret"
              component={TextField}
              type="password"
              autoComplete="new-password"
              validate={validateRequired}
              helperText="The access secret for authenticating to this bucket"
              disabled={disabled}
              required
            />
          </Fragment>
        ) : (
          <Field
            name="role.arn"
            label="AWS ARN"
            component={TextField}
            type="text"
            validate={validateRequired}
            helperText="The role ARN for authenticating to this bucket"
            disabled={disabled}
            required
          />
        )}
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

AwsTgwFields.propTypes = {
  disabled: PropTypes.bool,
  isDnsContext: PropTypes.bool,
};

AwsTgwFields.defaultProps = {
  disabled: false,
  isDnsContext: PropTypes.bool,
};

export default AwsTgwFields;
