import RoutePaths from '@/models/RoutePaths';

import { makeFilterUrlParamStr } from '@/redux/globalFilters';

export const getSearchUrl = ({ context, from, to, nql, customer }) => {
  const search = new URLSearchParams();
  const f = makeFilterUrlParamStr({
    context,
    from,
    to,
    nql: [nql || ''],
    customer,
  });
  search.append('f', f);
  return `${RoutePaths.search}?${search.toString()}`;
};
