import Palette from '../palette';
import { createThemeSlice } from '../util';

const themeSlice = createThemeSlice('table', (choose) => ({
  background: choose({
    light: '#F7F8FA',
    dark: '#2D2D31',
  }),

  /** * Header ** */
  colorHeaderBackground: choose({
    light: '#E0DFE2',
    dark: '#323237',
  }),
  colorHeaderGroupBackground: choose({
    light: 'rgba(0, 0, 0, 0.03)',
    dark: 'rgba(213, 213, 208, 0.03)',
  }),
  colorHeaderGroupBorder: choose({
    light: '#C0BFC5',
    dark: '#1A1B1D',
  }),
  colorHeaderBorder: choose({
    light: '#C0BFC5',
    dark: '#1A1B1D',
  }),
  colorHeaderBottomBorder: choose({
    light: '#C0BFC5',
    dark: '#1A1B1D',
  }),
  colorFiltersFieldsBorder: choose({
    light: '#C0BFC5',
    dark: '#8C8C8C',
  }),

  /** * Body ** */
  colorTdInBodyBorder: choose({
    light: '#E0DFE2',
    dark: '#1A1B1D',
  }),
  colorTrGroupInBodyBorder: choose({
    light: '#E0DFE2',
    dark: '#1A1B1D',
  }),
  colorSortBoxShadow: choose({
    light: Palette.primary,
    dark: Palette.primary,
  }),
  colorTrOddBackground: choose({
    light: '#FFFFFF',
    dark: '#26272A',
  }),
  colorTrHoverBackground: choose({
    light: 'rgba(21, 20, 18, 0.05)',
    dark: 'rgba(233, 232, 229, 0.05)',
  }),
  colorNoDataBackground: choose({
    light: 'rgba(255, 255, 255, 0.1)',
    dark: 'rgba(42, 42, 47, 0.1)',
  }),
  colorNoData: choose({
    light: '#35353B',
    dark: 'rgba(213, 213, 208, 0.6)',
  }),

  /** * Pagination ** */
  colorPaginationBackground: choose({
    light: '#FFFFFF',
    dark: '#26272A',
  }),
  colorPaginationBorder: choose({
    light: '#E0DFE2',
    dark: '#1A1B1D',
  }),
  colorPaginationButtonBackground: choose({
    light: '#F0F1F4',
    dark: '#2A2A2F',
  }),

  /** * Menu Column ** */
  menuTriggerColor: choose({
    light: '#19181B',
    dark: '#FFFFFF',
  }),
  menuTriggerBackground: choose({
    light: '#E0DFE2',
    dark: '#35353A',
  }),
  menuTriggerHoverColor: choose({
    light: '#19181B',
    dark: '#FFFFFF',
  }),
  menuTriggerHoverBackground: choose({
    light: '#C0BFC5',
    dark: '#65656C',
  }),
  menuTriggerActiveColor: choose({
    light: '#19181B',
    dark: '#16171A',
  }),
  menuTriggerActiveBackground: choose({
    light: Palette.primary,
    dark: Palette.primary,
  }),

  /** * Sub Details ** */
  recordSubDetailsTitleColor: choose({
    light: '#16171A',
    dark: '#FFFFFF',
  }),
  recordSubDetailsSubTitleColor: choose({
    light: '#707070',
    dark: '#A2A2A2',
  }),
  recordSubDetailsLinkButtonColor: choose({
    light: Palette.primary,
    dark: Palette.primary,
  }),
  recordSubDetailsBackground: choose({
    light: '#FFFFFF',
    dark: '#323237',
  }),
  recordSubDetailsBorderColor: choose({
    light: '#E0DFE2',
    dark: '#1A1B1D',
  }),
}));

export const { selector, selectors, propsSelector, propsSelectors } =
  themeSlice;
export default themeSlice;
