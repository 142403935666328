/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMeasure } from 'react-use';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

import { getFieldType } from '@/models/FieldTypes';
import { DataTypes } from '@/models/PropertiesTray';

import { selectors as customerSelectors } from '@/redux/api/customer';

import useUIProperty from '+hooks/useUIProperty';
import getNqlByFieldName from '+utils/getNqlByFieldName';
import { getStreamNql } from '+utils/getStreamNql';

import MenuWrapper from '../MenuWrapper';
import ScrollBar from '../ScrollBar';
import Container from './components/Container';
import FieldBody from './components/FieldBody';
import MenuRenderer from './components/MenuRenderer';
import NoData from './components/NoData';
import RecordBody from './components/RecordBody';
import Separator from './components/Separator';
import Title from './components/Title';
import TitleContainer from './components/TitleContainer';
import { getMainTitle, padding } from './components/utils';

const PropertiesTray = ({ $width, $separatorWidth }) => {
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const [menuRef, { height: menuHeight }] = useMeasure();

  const [propertiesTray] = useUIProperty('propertiesTray', null);
  const dataLength = propertiesTray?.data?.length || 0;

  const [activeDataIndex, setActiveDataIndex] = useState(null);

  const activeData = useMemo(() => {
    const data = propertiesTray?.data?.[activeDataIndex];
    if (!data) {
      return null;
    }

    if (data.dataType === DataTypes.field) {
      return {
        streamNql: getStreamNql(data?.stream),
        ...getNqlByFieldName({
          context: data?.context,
          field: data?.field,
          value: data?.value,
        }),
        fieldType: getFieldType(data.field),
        ...data,
      };
    }

    return data;
  }, [propertiesTray?.data, activeDataIndex]);

  const onTitleClick = useCallback(
    (index) => () =>
      setActiveDataIndex((prevValue) => (prevValue === index ? null : index)),
    [],
  );

  useEffect(() => {
    setActiveDataIndex(dataLength > 1 ? null : 0);
  }, [propertiesTray, dataLength]);

  const isPropertiesTrayEmpty = !dataLength;

  if (isPropertiesTrayEmpty) {
    return (
      <Container $width={$width} $separatorWidth={$separatorWidth}>
        <TitleContainer $padding={padding} $active $disabled>
          <Title>Dynamic Context</Title>
        </TitleContainer>

        <Separator $width={$separatorWidth} />

        <NoData padding={padding}>
          Welcome to the new Properties Tray
          <br />
          <br />
          Clicking on labels and values throughout the portal will show
          additional details and drill down options in this properties tray.
        </NoData>
      </Container>
    );
  }

  return (
    <MenuWrapper data={activeData}>
      <Container $width={$width} $separatorWidth={$separatorWidth}>
        <ScrollBar
          $marginBottom={Math.max(0, menuHeight - $separatorWidth * 2)}
        >
          {propertiesTray.data.map((item, index) => {
            const isSubAccountRecord =
              item?.customer && item?.customer !== customer?.shortname;
            return (
              <Fragment key={`propertiesTrayTitle-${index}`}>
                <TitleContainer
                  $padding={padding}
                  $active={activeDataIndex === index}
                  $disabled={dataLength === 1}
                  onClick={dataLength === 1 ? undefined : onTitleClick(index)}
                >
                  <Title
                    title={getMainTitle(item, {
                      showCustomer: isSubAccountRecord,
                    })}
                  >
                    {isSubAccountRecord && (
                      <AccountMultipleIcon
                        className="sub-account-icon"
                        size={16}
                      />
                    )}
                    {getMainTitle(item)}
                  </Title>
                  <ChevronRightIcon />
                </TitleContainer>

                <Separator $width={$separatorWidth} />

                {activeData?.dataType === DataTypes.field && (
                  <FieldBody
                    padding={padding}
                    activeData={activeData}
                    isOpen={activeDataIndex === index}
                    // onLabelClick={onLabelClick}
                    // onLabelEdit={onLabelEdit}
                  />
                )}

                {activeData?.dataType === DataTypes.record && (
                  <RecordBody
                    padding={padding}
                    activeData={activeData}
                    isOpen={activeDataIndex === index}
                  />
                )}

                {activeDataIndex === index && index !== dataLength - 1 && (
                  <Separator $width={$separatorWidth} />
                )}
              </Fragment>
            );
          })}
        </ScrollBar>

        <MenuRenderer ref={menuRef} $separatorWidth={$separatorWidth} />
      </Container>
    </MenuWrapper>
  );
};

PropertiesTray.propTypes = {
  $width: PropTypes.number.isRequired,
  $separatorWidth: PropTypes.number.isRequired,
};

export default PropertiesTray;
