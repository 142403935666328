import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from 'react-use';

import {
  actions as globalFiltersActions,
  selectors as globalFiltersSelectors,
} from '@/redux/globalFilters';
import { selectors as globalFiltersUiSelectors } from '@/redux/globalFilters/ui';

import { usePageTabs } from '+components/PageTabs';

const withRealTimeController = (Component) => (props) => {
  const dispatch = useDispatch();

  const [, activePageTab] = usePageTabs();

  const refresher = useSelector(
    globalFiltersSelectors.getRefresher(activePageTab?.id),
  );
  const autoRefresh = useSelector(
    globalFiltersSelectors.getAutoRefresh(activePageTab?.id),
  );
  const updateEvery = useSelector(globalFiltersUiSelectors.getUpdateEvery);
  const available = useSelector(globalFiltersUiSelectors.getAvailable);

  const [time, setTime] = useState(null);

  useEffect(() => {
    if (!available || !autoRefresh) {
      setTime((prev) => (prev === null ? prev : null));
      return;
    }
    setTime(Math.max(+updateEvery, 0) || 60e3);
  }, [available, autoRefresh, updateEvery, refresher]);

  useInterval(() => {
    dispatch(globalFiltersActions.refreshRefresherAuto());
  }, time);

  return useMemo(() => <Component {...props} />, Object.values(props));
};

export default withRealTimeController;
