export const FeatureFlags = {
  showRealTimeFlowMap: 'showRealTimeFlowMap',
  guestAccess: 'guestAccess',
  notificationPopupsManager: 'notificationPopupsManager',
  rolesUiSettings: 'rolesUiSettings',
  resizableTray: 'resizableTray',
  dns: 'DNS',
};

export default FeatureFlags;
