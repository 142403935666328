import PropTypes from '+prop-types';
import { useMemo } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { makeId } from '+utils/general';

const Label = styled.label`
  display: inline-flex;
  padding: 4px 0;
  margin-bottom: 0;

  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.$readOnly ? 'none' : 'inherit')};
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  gap: 7px;

  .toggle-input {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-line {
    text-indent: -9999px;
    display: block;
    position: relative;
    margin-bottom: 0;
    border: none;

    margin-left: 0;
    margin-right: 0;
    pointer-events: none;

    width: 1.8572em;
    height: 1em;

    border-radius: 1em;
    background: ${({ theme }) => theme.toggleUncheckedBackground};
    transition:
      background 0.3s,
      box-shadow 0.1s;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      transition: left 0.3s;
      transform: translate(0, -50%);
      background-color: ${({ theme }) => theme.toggleThumbColor};
    }
  }

  .toggle-input:focus-visible ~ .toggle-line {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.toggleFocusBorderColor};
  }

  .toggle-line.toggle-consistent {
    background: ${({ theme }) => theme.toggleCheckedBackground} !important;
  }

  .toggle-input:checked ~ .toggle-line:not(.toggle-flipped) {
    background: ${({ theme }) => theme.toggleCheckedBackground} !important;

    &::after {
      left: calc(100% - 1em);
    }
  }

  .toggle-input:not(:checked) ~ .toggle-flipped {
    background: ${({ theme }) => theme.toggleCheckedBackground} !important;

    &::after {
      left: calc(100% - 1em);
    }
  }

  .toggle-text,
  .toggle-children {
    line-height: 1;
    white-space: nowrap;
    color: ${({ theme }) => theme.colorText};
  }

  & .toggle-children {
    margin-left: 5px;
    margin-right: 5px;
  }

  & .toggle-text {
    font-size: 12px;
    text-transform: ${(props) =>
      props.$uppercaseLabels ? 'uppercase' : 'none'};
  }
`;

const Toggle = (props) => {
  const {
    className,
    checked,
    onChange,
    children,
    id,
    name,
    input,
    uncheckedLabel,
    checkedLabel,
    disabled,
    readOnly,
    flipped,
    uppercaseLabels,
    twoOptionToggle,
    dataTracking,
  } = props;

  // FIXME:
  //  For some reason, the input checked value is always true and doesn't update
  //  As a workaround, we'll use the input value as class name
  const localChecked = input?.checked !== undefined ? input.checked : checked;
  const UID = useMemo(() => makeId() + id, [id]);

  return (
    <Label
      className={classNames(className, 'toggle', {
        disabled,
        readOnly,
        checked: localChecked,
      })}
      htmlFor={UID}
      $disabled={disabled}
      $readOnly={readOnly}
      $uppercaseLabels={uppercaseLabels}
      data-tracking={`${dataTracking}-toggle`}
    >
      <input
        className="toggle-input"
        type="checkbox"
        name={name}
        id={UID}
        checked={localChecked}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        {...(input || {})}
      />
      {!!uncheckedLabel && (
        <span className="toggle-text">{uncheckedLabel}</span>
      )}
      <div
        className={classNames('toggle-line', {
          'toggle-flipped': flipped,
          'toggle-consistent': twoOptionToggle,
        })}
        aria-label={`${checkedLabel} - ${uncheckedLabel} toggle`}
        aria-pressed={checked}
      />
      {!!checkedLabel && <span className="toggle-text">{checkedLabel}</span>}
      {children && <span className="toggle-children">{children}</span>}
    </Label>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  dataTracking: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.children,
  id: PropTypes.string,
  name: PropTypes.string,
  uncheckedLabel: PropTypes.string,
  checkedLabel: PropTypes.string,
  input: PropTypes.shape(),
  flipped: PropTypes.bool,
  uppercaseLabels: PropTypes.bool,
  twoOptionToggle: PropTypes.bool,
};

Toggle.defaultProps = {
  className: '',
  checked: false,
  dataTracking: 'undefined',
  disabled: false,
  readOnly: false,
  onChange: null,
  children: null,
  id: '',
  name: '',
  uncheckedLabel: null,
  checkedLabel: null,
  flipped: false,
  input: null,
  uppercaseLabels: true,
  twoOptionToggle: false,
};

export default Toggle;
