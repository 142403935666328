import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';
import SettingCategories from '@/models/SettingCategories';
import StatsRequest from '@/models/StatsRequest';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import { selectors as selectorsCustomer } from '@/redux/api/customer';
import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';
import { selectors as nqlCompleteSelectors } from '@/redux/api/nql-complete';
import { selectors as portalSettingsSelectors } from '@/redux/api/portalSettings';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import ArrayNQLField from '+components/form/ArrayNQLField';
import ButtonGroupField from '+components/form/ButtonGroupField';
import CheckBoxField from '+components/form/CheckBox';
import ColorPickerFieldOrigin from '+components/form/ColorPickerField';
import { DateTimePickerField } from '+components/form/DateTimePicker';
import FieldsRow from '+components/form/FieldsRow';
import FieldsSectionOrigin from '+components/form/FieldsSection';
import {
  Field,
  FieldArray,
  FORM_ERROR,
  useForm,
  useFormState,
} from '+components/form/FinalForm';
import MultiSelectField from '+components/form/MultiSelectField';
import {
  normalizeDateTimePickerValue,
  normalizeMultiSelectValue,
  normalizeNumber,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import TextFieldOrigin from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import {
  validateDateTime,
  validateDateTimeBetween,
  validateDateTimeCannotBeSame,
  validateMinMaxValue,
  validateRequired,
} from '+components/form/Validators';
import FormModal from '+components/FormModal';
import { NumberField } from '+components/GlobalFilters/Components';
import useGlobalFilters from '+hooks/useGlobalFilters';
import usePermissions from '+hooks/usePermissions';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';
import dayjs from '+utils/dayjs';
import { getHighchartsColor } from '+utils/getHighchartsColor';

import NqlModeTypes from '../shared/NqlModeTypes';
import { dashboardPropTypes, defaultColorVariety } from '../shared/propTypes';
import { getDashboardNql } from '../shared/requests';
import {
  dashboardParamsToUi,
  dashboardUiToParams,
  makePaletteBackground,
} from '../shared/utils';

const nqlModeOptions = [
  { value: NqlModeTypes.global, label: 'Global' },
  { value: NqlModeTypes.override, label: 'Override' },
  { value: NqlModeTypes.append, label: 'Append' },
];

const FieldsSection = styled(FieldsSectionOrigin)`
  gap: 10px;

  .fields-section-label {
    padding-left: 75px;
    font-weight: bold;
    margin-bottom: unset;
    &:after {
      margin-left: 20px;
    }
  }

  label.checkbox {
    margin-left: 140px;
  }
`;

const TextField = styled(TextFieldOrigin)`
  &[disabled] {
    opacity: 0.6 !important;
    border: 1px solid ${({ theme }) => theme.colorFieldsBorder} !important;
    padding: 0 7px !important;
    height: 32px !important;
  }
`;

const ColorPickerField = styled(ColorPickerFieldOrigin)`
  .container {
    flex-wrap: wrap;
    border-radius: 4px;
    padding: 5px 10px;
    background-color: ${({ theme }) => theme.colorPickerBackground};
  }
`;

const FormBody = (props) => {
  const {
    initialValues,
    maxNqlQueries,
    maxIntersects,
    intersectFieldsOptions,
    groupsOptions,
    disabled,
  } = props;

  const [guest] = useUIProperty('guest');

  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);

  const permissionsAccount = usePermissions(
    PermissionModel.Resources.account.value,
  );

  const companyHomepageDashboardSettingsMeta = useSelector(
    portalSettingsSelectors.getCategoryPropertySelector(
      SettingCategories.dashboard,
      'companyHomepageDashboardId',
    ),
  );
  const eventTemplateDashboardSettingsMeta = useSelector(
    portalSettingsSelectors.getCategoryPropertySelector(
      SettingCategories.dashboard,
      'eventTemplateDashboardId',
    ),
  );

  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const { periodType, from, to } = formValues.dateTime || {};
  const isPeriodTypeCustom = periodType === CustomType;
  const dateFrom = from ? new Date(from) : null;
  const dateTo = to ? new Date(to) : null;

  const retention = useSelector(selectorsCustomer.getRetention);

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);
  const profile = useSelector(profileSelectors.getProfile);
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  const [filters] = useGlobalFilters(formValues.context || ContextTypes.flow);

  const dateTimeLimit = useMemo(() => {
    if (isRolesUiSettingsEnabled && userRoleUiSettings?.dateTimeLimit) {
      return Math.min(userRoleUiSettings.dateTimeLimit, retention);
    }
    return retention;
  }, [retention, isRolesUiSettingsEnabled, userRoleUiSettings?.dateTimeLimit]);

  const now = new Date();
  const max = new Date(
    +now.getFullYear(),
    +now.getMonth(),
    +now.getDate(),
    23,
    59,
    59,
  );
  const min = useMemo(
    () =>
      dayjs(now - TimeDuration.day * dateTimeLimit)
        .millisecond(0)
        .toDate(),
    [dateTimeLimit],
  );

  const minPeriodValue = 1;

  const maxPeriodValue = useMemo(() => {
    if (formValues.dateTime?.periodType === CustomType) {
      return undefined;
    }
    return Math.floor(
      (dateTimeLimit * TimeDuration.day) /
        (formValues.dateTime?.periodType || 1),
    );
  }, [dateTimeLimit, formValues.dateTime?.periodType]);

  const periodOptions = useMemo(() => {
    const allowedPeriods = [
      TimeDuration.minute,
      TimeDuration.hour,
      TimeDuration.day,
      TimeDuration.week,
      TimeDuration.month,
    ];
    return [
      {
        value: CustomType,
        label: 'Custom Range',
      },
      ...allowedPeriods.map((value) => ({
        value,
        label: TimePeriods[value].name,
      })),
    ];
  }, []);

  const colorVarietyOptions = useMemo(
    () =>
      new Array(10).fill(null).map((_, paletteIndex) => {
        const colors = new Array(10).fill(null).map((__, colorIndex) =>
          getHighchartsColor({
            paletteClassName: 'p',
            paletteIndex,
            colorClassName: 'highcharts-color',
            colorIndex,
          }),
        );
        return makePaletteBackground(colors);
      }),
    [],
  );

  const nqlFieldLength = useMemo(
    () => formValues.nql?.filter((item) => !item?.hidden).length || 0,
    [formValues.nql],
  );

  const fixedMaxNqlQueries = useMemo(() => {
    if (formValues.nqlMode === NqlModeTypes.global) {
      return 0;
    }
    if (formValues.nqlMode === NqlModeTypes.append) {
      return 1;
    }
    return maxNqlQueries;
  }, [formValues.nqlMode, maxNqlQueries]);

  const nqlDocTemplateConfig = useMemo(
    () => ({ showIntersectable: fixedMaxNqlQueries > 1 }),
    [fixedMaxNqlQueries],
  );

  const fullNqlString = useMemo(
    () => getDashboardNql(formValues, filters.nql).join(', '),
    [formValues, filters.nql],
  );

  const periodTypeValidator = useMemo(() => {
    if (isPeriodTypeCustom) {
      return undefined;
    }
    return [
      validateRequired,
      validateMinMaxValue({
        min: 1,
        max: maxPeriodValue,
        fieldName: 'dateTime.periodValue',
      }),
    ];
  }, [maxPeriodValue, isPeriodTypeCustom, formValues.dateTime?.periodType]);

  const fromDateValidator = useMemo(
    () => [
      validateRequired,
      validateDateTime,
      validateDateTimeCannotBeSame({
        fieldName: 'dateTime.to',
        errorMessage: 'From date and To date cannot be the same.',
      }),
      validateDateTimeBetween({
        min,
        maxFieldName: 'dateTime.to',
        includeMin: true,
        includeMax: false,
      }),
    ],
    [min],
  );

  const toDateValidator = useMemo(
    () => [
      validateRequired,
      validateDateTime,
      validateDateTimeCannotBeSame({
        fieldName: 'dateTime.from',
        errorMessage: ' ',
      }),
      validateDateTimeBetween({
        minFieldName: 'dateTime.from',
        max,
        includeMin: false,
        includeMax: true,
      }),
    ],
    // To prevent form validation looping do not put 'max' to deps (validator will update if form values changed)
    // @see: https://netography.atlassian.net/browse/PORTAL-1415
    [formValues],
  );

  const formSettings = useMemo(() => {
    const isNqlEnabled = formValues.nqlMode !== NqlModeTypes.global;
    return {
      isNqlEnabled,
    };
  }, [formValues.nqlMode]);

  // NQL Field
  useEffect(() => {
    // clear nql if it's not allowed
    if (!formSettings.isNqlEnabled) {
      form.change('nql', null);
      return;
    }

    // change nql to default if it's value not allowed
    const isNqlAllowed = formValues.nql != null;
    if (!isNqlAllowed) {
      form.change('nql', ['']);
    }
  }, [formSettings.isNqlEnabled, formValues.nql]);

  useEffect(() => {
    if (nqlFieldLength === 1 && !!formValues.intersect?.length) {
      form.change('intersect', []);
    }
  }, [formValues.intersect, nqlFieldLength]);

  // Period Value Field
  useEffect(() => {
    if (formSettings.isCustomDateTimePeriodEnabled) {
      return;
    }
    if (formValues.dateTime?.periodType === CustomType) {
      return;
    }
    if (formValues.dateTime?.periodValue < minPeriodValue) {
      form.change('dateTime.periodValue', minPeriodValue);
      return;
    }
    if (formValues.dateTime?.periodValue > maxPeriodValue) {
      form.change('dateTime.periodValue', maxPeriodValue);
    }
  }, [
    formValues.dateTime?.periodType,
    formValues.dateTime?.periodValue,
    minPeriodValue,
    maxPeriodValue,
  ]);

  const isPublicDisabled =
    formValues.isCompanyHomepage || formValues.isEventTemplate;

  return (
    <Fragment>
      <Field
        name="title"
        label="Name"
        component={TextField}
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <Field
        name="description"
        label="Description"
        component={TextField}
        disabled={disabled}
      />

      <Field
        name="groups"
        label="Collection"
        component={MultiSelectField}
        options={groupsOptions}
        parse={normalizeMultiSelectValue}
        disabled={disabled}
        allowCreate
      />

      <Field
        name="useCustomDateTime"
        label="Date & Time"
        component={ToggleField}
        type="checkbox"
        uncheckedLabel="Inherit"
        checkedLabel="Custom"
        disabled={disabled}
        twoOptionToggle
      />

      {formValues.useCustomDateTime && (
        <Fragment>
          <FieldsRow>
            <div style={{ width: '30%', margin: '0 10px 0 140px' }}>
              <Field
                name="dateTime.periodValue"
                component={NumberField}
                min={minPeriodValue}
                max={maxPeriodValue}
                validate={periodTypeValidator}
                parse={normalizeNumber}
                disabled={disabled || isPeriodTypeCustom}
                required={!isPeriodTypeCustom}
              />
            </div>
            <Field
              name="dateTime.periodType"
              component={SelectField}
              options={periodOptions}
              validate={validateRequired}
              parse={normalizeSelectValue}
              disabled={disabled}
              required
            />
          </FieldsRow>

          {isPeriodTypeCustom && (
            <Fragment>
              <Field
                name="dateTime.from"
                label="From"
                component={DateTimePickerField}
                defaultValue={dateFrom}
                min={min}
                max={dateTo ?? now}
                validate={fromDateValidator}
                parse={normalizeDateTimePickerValue}
                disabled={disabled}
                required
              />

              <Field
                name="dateTime.to"
                label="To"
                component={DateTimePickerField}
                defaultValue={dateTo}
                min={dateFrom ?? min}
                max={max}
                validate={toDateValidator}
                parse={normalizeDateTimePickerValue}
                disabled={disabled}
                required
              />
            </Fragment>
          )}
        </Fragment>
      )}

      <Field
        name="nqlMode"
        label="NQL Mode"
        options={nqlModeOptions}
        component={ButtonGroupField}
        disabled={disabled}
      />

      {formSettings.isNqlEnabled && (
        <FieldArray
          name="nql"
          label="NQL"
          helperText={fullNqlString}
          component={ArrayNQLField}
          context={formValues.context}
          maxLength={fixedMaxNqlQueries}
          docTemplateConfig={nqlDocTemplateConfig}
          disabled={disabled}
          required={formValues.nqlMode !== NqlModeTypes.global}
        />
      )}

      {nqlFieldLength > 1 && (
        <Field
          name="intersect"
          label="Intersect By"
          component={MultiSelectField}
          options={intersectFieldsOptions}
          limit={maxIntersects}
          helperText={`Max ${maxIntersects} fields`}
          validate={validateRequired}
          parse={normalizeMultiSelectValue}
          disabled={disabled}
          required
        />
      )}

      <Field
        name="colorVariety"
        label="Color Palette"
        component={ColorPickerField}
        options={colorVarietyOptions}
        defaultActive={defaultColorVariety}
        disabled={disabled}
      />

      <FieldsSection label="Options">
        <Field
          name="public"
          component={CheckBoxField}
          type="checkbox"
          label="Public Visibility"
          disabled={disabled || isPublicDisabled}
        />
        <Field
          name="preventAutoLoad"
          component={CheckBoxField}
          type="checkbox"
          label="Prevent auto loading (requires NQL search)"
          disabled={disabled}
        />

        <Field
          name="isFavorite"
          component={CheckBoxField}
          type="checkbox"
          label="Add to Favorites"
          disabled={disabled || !initialValues.id || guest}
        />

        <Field
          name="isUserHomepage"
          component={CheckBoxField}
          type="checkbox"
          label="Set as Your Homepage"
          disabled={disabled || !initialValues.id || guest}
        />

        <Field
          name="isCompanyHomepage"
          component={CheckBoxField}
          type="checkbox"
          label="Set as Company Homepage"
          disabled={
            disabled ||
            !initialValues.id ||
            !initialValues.public ||
            !permissionsAccount?.update ||
            (formValues.isCompanyHomepage &&
              companyHomepageDashboardSettingsMeta?.system &&
              !isDefaultCustomer)
          }
        />

        <Field
          name="isEventTemplate"
          component={CheckBoxField}
          type="checkbox"
          label="Set as Event Template"
          disabled={
            disabled ||
            !initialValues.id ||
            !initialValues.public ||
            !permissionsAccount?.update ||
            (formValues.isEventTemplate &&
              eventTemplateDashboardSettingsMeta?.system &&
              !isDefaultCustomer)
          }
        />

        {isDefaultCustomer && (
          <Field
            name="hidden"
            component={CheckBoxField}
            type="checkbox"
            label="Hidden Dashboard"
            helperText="If checked, the dashboard will not appear in the manage dashboards list outside the default customer."
            disabled={disabled}
          />
        )}
      </FieldsSection>
    </Fragment>
  );
};

FormBody.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    public: PropTypes.bool,
    isFavorite: PropTypes.bool,
    isCompanyHomepage: PropTypes.bool,
    isUserHomepage: PropTypes.bool,
    isEventTemplate: PropTypes.bool,
  }).isRequired,
  maxNqlQueries: PropTypes.number,
  maxIntersects: PropTypes.number,
  intersectFieldsOptions: PropTypes.arrayOf(PropTypes.shape({})),
  groupsOptions: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
};

FormBody.defaultProps = {
  maxNqlQueries: 1,
  maxIntersects: 0,
  intersectFieldsOptions: [],
  groupsOptions: [],
  disabled: false,
};

const DashboardForm = (props) => {
  const {
    dashboard,
    editMode,
    isOpen,
    toggleModal,
    onConfirm,
    onSave,
    disabled,
    ...tail
  } = props;

  const dispatch = useDispatch();

  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);

  const maxNqlQueries =
    StatsRequest.StatsConfig[dashboard.context]?.maxNqlQueries ?? 1;
  const maxIntersects =
    StatsRequest.StatsConfig[dashboard.context]?.maxIntersects ?? 0;

  const docs = useSelector(nqlCompleteSelectors.getDocs);

  const [dashboardFavorites, setDashboardFavorites] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'favorites',
    [],
  );
  const [companyHomepageDashboard, setCompanyHomepageDashboard] =
    usePortalSettingsValue(
      SettingCategories.dashboard,
      'companyHomepageDashboardId',
    );
  const [userHomepageDashboard, setUserHomepageDashboard] =
    usePortalSettingsValue(
      SettingCategories.dashboard,
      'userHomepageDashboardId',
    );
  const [eventTemplateDashboard, setEventTemplateDashboard] =
    usePortalSettingsValue(
      SettingCategories.dashboard,
      'eventTemplateDashboardId',
    );

  const initialValues = useMemo(() => {
    const values = dashboardParamsToUi(dashboard || {});
    return {
      ...values,
      isFavorite: dashboardFavorites.includes(values.id),
      isCompanyHomepage: companyHomepageDashboard === values.id,
      isUserHomepage: userHomepageDashboard === values.id,
      isEventTemplate: eventTemplateDashboard === values.id,
    };
  }, [
    maxNqlQueries,
    dashboard,
    dashboardFavorites,
    companyHomepageDashboard,
    userHomepageDashboard,
    eventTemplateDashboard,
  ]);

  const intersectFieldsOptions = useMemo(
    () =>
      Object.entries(docs?.docs?.fields?.[dashboard.context] || {}).reduce(
        (acc, item) => {
          const [fieldName, fieldMeta] = item;
          if (!fieldMeta.intersectable) {
            return acc;
          }
          return [
            ...acc,
            {
              value: fieldName,
              label: fieldName,
              description: fieldMeta.description,
            },
          ];
        },
        [],
      ),
    [docs?.docs?.fields?.[dashboard.context]],
  );

  const groupsOptions = useMemo(() => {
    const groups = Object.values(dashboardsMeta || {}).flatMap(
      (item) => item.groups || [],
    );
    return [...new Set(groups)].sort().map((item) => ({
      value: item,
      label: item,
    }));
  }, [dashboardsMeta]);

  const onToggleFavorite = useCallback(
    (id) => {
      const favoritesSet = new Set(dashboardFavorites);
      if (favoritesSet.has(id)) {
        favoritesSet.delete(id);
      } else {
        favoritesSet.add(id);
      }
      setDashboardFavorites([...favoritesSet]);
    },
    [dashboardFavorites, setDashboardFavorites],
  );

  const onToggleUserHomepage = useCallback(
    (id) => {
      setUserHomepageDashboard(id === userHomepageDashboard ? null : id);
    },
    [userHomepageDashboard, setUserHomepageDashboard],
  );

  const onToggleCompanyHomepage = useCallback(
    (id) => {
      setCompanyHomepageDashboard(
        id === companyHomepageDashboard ? null : id,
        true,
      );
    },
    [companyHomepageDashboard, setCompanyHomepageDashboard],
  );

  const onToggleEventTemplate = useCallback(
    (id) => {
      setEventTemplateDashboard(
        id === eventTemplateDashboard ? null : id,
        true,
      );
    },
    [eventTemplateDashboard, setEventTemplateDashboard],
  );

  const onSubmit = useCallback(
    (values) => {
      if (initialValues.isFavorite !== values.isFavorite) {
        onToggleFavorite(values.id);
      }

      if (initialValues.isUserHomepage !== values.isUserHomepage) {
        onToggleUserHomepage(values.id);
      }

      if (initialValues.isCompanyHomepage !== values.isCompanyHomepage) {
        onToggleCompanyHomepage(values.id);
      }

      if (initialValues.isEventTemplate !== values.isEventTemplate) {
        onToggleEventTemplate(values.id);
      }

      const _values = {
        ...values,
        description: values.description || '',
      };

      delete _values.isFavorite;
      delete _values.isUserHomepage;
      delete _values.isCompanyHomepage;
      delete _values.isEventTemplate;

      const promise = onSave(dashboardUiToParams(_values));
      onConfirm?.();
      return promise;
    },
    [
      initialValues,
      onToggleFavorite,
      onToggleUserHomepage,
      onToggleCompanyHomepage,
      onToggleEventTemplate,
      onSave,
      onConfirm,
    ],
  );

  const doSubmit = useCallback(
    async (values) => {
      const errors = await onSubmit(values);
      return errors ? { [FORM_ERROR]: errors } : null;
    },
    [onSubmit],
  );

  useEffect(() => {
    if (!isAllMetaFetched) {
      dispatch(dashboardsActions.fetchDashboardsMeta());
    }
  }, [isAllMetaFetched]);

  return (
    <FormModal
      mode={editMode ? 'edit' : 'add'}
      item={`${dashboard.title || 'new'} dashboard`}
      initialValues={initialValues}
      isOpen={isOpen}
      onSubmit={doSubmit}
      onToggle={toggleModal}
      disabled={disabled}
      validateOnBlur
      {...tail}
    >
      <FormBody
        initialValues={initialValues}
        maxNqlQueries={maxNqlQueries}
        maxIntersects={maxIntersects}
        intersectFieldsOptions={intersectFieldsOptions}
        groupsOptions={groupsOptions}
        disabled={disabled}
      />
    </FormModal>
  );
};

DashboardForm.propTypes = {
  dashboard: PropTypes.shape(dashboardPropTypes),
  editMode: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func,
  onConfirm: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DashboardForm.defaultProps = {
  dashboard: {},
  editMode: false,
  onConfirm: null,
  disabled: false,
  toggleModal: null,
};

export default DashboardForm;
