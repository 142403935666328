import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import styled from 'styled-components';

import PermissionModel from '@/models/Permission';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

import Button, { ButtonVariants } from '+components/Button';
import { Field, FinalForm } from '+components/form/FinalForm';
import { Group } from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValueAndParseCommas } from '+components/form/Normalizers';
import { validateIpOrCidrs } from '+components/form/Validators';
import { Col, Row } from '+components/Layout';
import usePermissions from '+hooks/usePermissions';

import ImportCsvForm from './ImportCsvForm';

const Form = styled.form`
  width: 80%;
  background-color: transparent;
  margin-bottom: 40px;
`;

const StyledButton = styled(Button)`
  margin-left: 140px;
  margin-right: 8px;
`;

const FormBody = (props) => {
  const { handleSubmit } = props;
  const [ipOptions] = useState([]);

  const [showImportCsvModal, toggleImportCsvModal] = useToggle(false);

  const permissions = usePermissions(
    PermissionModel.Resources.network_classification.value,
  );

  const canManage = permissions?.update;

  const { isFetching } = useSelector(customerSelectors.getState);

  return (
    <Form
      className="form form--horizontal wizard__form"
      onSubmit={handleSubmit}
    >
      <Row>
        <Col lg={7} className="form form--horizontal" item container={false}>
          <Group>
            <Field
              name="allowedIPs"
              component={MultiSelectField}
              options={ipOptions}
              parse={normalizeMultiSelectValueAndParseCommas}
              validate={validateIpOrCidrs}
              allowCreate
              disabled={isFetching || !canManage}
              label="Inbound IP Allow List"
              helperText="List of Classless Inter-Domain Routings (CIDRs) or IPs which are allowed access to Fusion. If left blank, there is no restriction. IPs & CIDRs can be imported from txt or csv or pasted as list. Example: 1.1.1.1, 2.2.2.2, 3.3.3.3/16"
            />
          </Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Group>
            <StyledButton
              variant={ButtonVariants.outlined}
              disabled={!canManage}
              onClick={toggleImportCsvModal}
            >
              Import IPs
            </StyledButton>
            <Button type="submit" disabled={isFetching || !canManage}>
              Save Allowed IPs
            </Button>
          </Group>
        </Col>
      </Row>

      {showImportCsvModal && (
        <ImportCsvForm
          initialValues={{}}
          onToggle={toggleImportCsvModal}
          isOpen
        />
      )}
    </Form>
  );
};

FormBody.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const IpSection = () => {
  const dispatch = useDispatch();
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const { allowedIPs } = useSelector(customerSelectors.getState);

  useEffect(() => {
    dispatch(customerActions.requestAllowedIps(customer.shortname));
  }, []);

  const initialValues = useMemo(() => {
    return { allowedIPs };
  }, [allowedIPs]);

  const onSubmit = useCallback((values) => {
    dispatch(customerActions.saveAllowedIPs(values.allowedIPs));
  }, []);

  return (
    <FinalForm
      onSubmit={onSubmit}
      component={FormBody}
      initialValues={initialValues}
    />
  );
};

export default IpSection;
