import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import FeatureFlags from '@/models/FeatureFlags';
import RoutePaths from '@/models/RoutePaths';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { config } from '@/config';
import AccountOverviewPage from '@/pages/AccountOverview';
import ApiKeysPage from '@/pages/ApiKeys';
import AuditDetails from '@/pages/AuditLogDetails';
import AuditLogs from '@/pages/AuditLogs';
import { BillingPage } from '@/pages/Billing';
import CustomersPage from '@/pages/Customers';
import DetectionCategories from '@/pages/DetectionCategories';
import AddIntegration from '@/pages/Integrations/AddIntegration';
import ContextIntegrationsPage from '@/pages/Integrations/ContextIntegrations';
import ContextIntegrationsAdd from '@/pages/Integrations/ContextIntegrations/Add';
import ContextIntegrationsEdit from '@/pages/Integrations/ContextIntegrations/Edit';
import { NqlPresets } from '@/pages/NqlPresets';
import ProfilePages from '@/pages/Profile';
import RolePage from '@/pages/RolePage';
import RolesPage from '@/pages/Roles';
import SsoFormPage from '@/pages/SamlSettings';
import SecurityPage from '@/pages/Security';
import SettingsLayout from '@/pages/SettingsLayout';
import TrafficClassification from '@/pages/TrafficClassification';
import UserPage from '@/pages/UserPage';
import UsersPage from '@/pages/Users';

import { CrumbRoute } from '+components/Breadcrumb';
import useRoles from '+hooks/useRoles';

import FlowTagsRoutes from './FlowTagsRoutes';
import { IPTrustListsRoutes } from './IPTrustListsRoutes';
import LabelsRoutes from './LabelsRoutes';
import ResponseIntegrationsRoutes from './ResponseIntegrationsRoutes';
import ResponsePoliciesRoutes from './ResponsePoliciesRoutes';
import SourcesRoutes from './SourcesRoutes';
import { withReplaceRoute } from './withReplaceRoute';

const FromNetworkToTraffic = withReplaceRoute(
  RoutePaths.networkClassification,
  RoutePaths.trafficClassification,
);

const FromTrafficToNetwork = withReplaceRoute(
  RoutePaths.trafficClassification,
  RoutePaths.networkClassification,
);

const AdminRoutes = () => {
  const isDnsEnabled = useFlag(FeatureFlags.dns);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const { roles } = useRoles();
  const profile = useSelector(profileSelectors.getProfile);
  const canManageSubscription =
    !!roles?.[profile?.roles?.[0]]?.canManageSubscription;
  const showSubscription = customer?.type === 'plg' && canManageSubscription;

  return (
    <SettingsLayout>
      <Routes>
        <Route
          path={RoutePaths.profile.pageName}
          element={
            <CrumbRoute title="Profile Details" component={ProfilePages} />
          }
        />

        <Route
          path={RoutePaths.profilePersonalization.pageName}
          element={
            <CrumbRoute
              title="Profile Personalization"
              component={ProfilePages}
            />
          }
        />

        <Route
          path={RoutePaths.profileSecurity.pageName}
          element={
            <CrumbRoute title="Profile Security" component={ProfilePages} />
          }
        />

        <Route
          path={RoutePaths.profileActivity.pageName}
          element={
            <CrumbRoute title="Profile Activity" component={ProfilePages} />
          }
        />

        <Route
          path={`${RoutePaths.searchAuditLogs.pageName}/:id`}
          element={
            <CrumbRoute title="Audit Log Details" component={AuditDetails} />
          }
        />
        <Route
          path={`${RoutePaths.searchAuditLogs.pageName}`}
          element={
            <CrumbRoute
              title="Audit Logs"
              component={AuditLogs}
              componentProps={{
                defaultFromHours: 24,
              }}
            />
          }
        />

        <Route
          path={RoutePaths.apiKeys.pageName}
          element={<CrumbRoute title="API Keys" component={ApiKeysPage} />}
        />

        <Route
          path={`${RoutePaths.users.pageName}/:id`}
          element={<CrumbRoute component={UserPage} />}
        />
        <Route
          path={RoutePaths.users.pageName}
          element={<CrumbRoute title="Users" component={UsersPage} />}
        />

        <Route
          path={`${RoutePaths.roles.pageName}/add`}
          element={
            <CrumbRoute
              title="Add Role"
              path={`../${RoutePaths.roles.pageName}`}
              component={RolePage}
            />
          }
        />
        <Route
          path={`${RoutePaths.roles.pageName}/:id/*`}
          element={
            <CrumbRoute
              title="Edit Role"
              path={`../${RoutePaths.roles.pageName}`}
              component={RolePage}
            />
          }
        />
        <Route
          path={RoutePaths.roles.pageName}
          element={<CrumbRoute title="Roles" component={RolesPage} />}
        />

        <Route
          path={RoutePaths.customers.pageName}
          element={<CrumbRoute title="Customers" component={CustomersPage} />}
        />

        <Route
          path={RoutePaths.samlSettings.pageName}
          element={
            <CrumbRoute
              title="SAML Single Sign-On Settings"
              component={SsoFormPage}
            />
          }
        />
        <Route
          path={RoutePaths.security.pageName}
          element={
            <CrumbRoute title="Global Security/SSO" component={SecurityPage} />
          }
        />

        <Route
          path={`${RoutePaths.sources.pageName}/*`}
          element={
            <CrumbRoute
              title={`${isDnsEnabled ? 'Traffic' : 'Flow'} Sources`}
              component={SourcesRoutes}
            />
          }
        />

        <Route path={`${RoutePaths.integrationsContext.pageName}/*`}>
          <Route
            path="edit/:id/*"
            element={
              <CrumbRoute
                title="Edit Context Integration"
                component={ContextIntegrationsEdit}
              />
            }
          />

          <Route
            path="add/:adapter"
            element={
              <CrumbRoute
                title="Add Context Integration"
                component={ContextIntegrationsAdd}
              />
            }
          />

          <Route
            path="add"
            element={
              <CrumbRoute title="Add Integration" component={AddIntegration} />
            }
          />

          <Route
            path="*"
            element={
              <CrumbRoute
                title="Context Integrations"
                component={ContextIntegrationsPage}
              />
            }
          />
        </Route>

        <Route
          path={`${RoutePaths.labels.pageName}/*`}
          element={
            <CrumbRoute title="Context Labels" component={LabelsRoutes} />
          }
        />

        <Route
          path={`${RoutePaths.flowTags.pageName}/*`}
          element={<CrumbRoute title="Flow Tags" component={FlowTagsRoutes} />}
        />

        {isDnsEnabled ? (
          <Fragment>
            <Route
              path={`${RoutePaths.trafficClassification.pageName}/*`}
              element={
                <CrumbRoute
                  title="Traffic Classifications"
                  component={TrafficClassification}
                />
              }
            />
            <Route
              path={`${RoutePaths.networkClassification.pageName}/*`}
              element={<FromNetworkToTraffic />}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Route
              path={`${RoutePaths.networkClassification.pageName}/*`}
              element={
                <CrumbRoute
                  title="Network Classifications"
                  component={TrafficClassification}
                />
              }
            />
            <Route
              path={`${RoutePaths.trafficClassification.pageName}/*`}
              element={<FromTrafficToNetwork />}
            />
          </Fragment>
        )}

        <Route
          path={RoutePaths.detectionCategories.pageName}
          element={
            <CrumbRoute
              title="Detection Categories"
              component={DetectionCategories}
            />
          }
        />

        <Route
          path={RoutePaths.nqlPresets.pageName}
          element={<CrumbRoute title="NQL Presets" component={NqlPresets} />}
        />

        <Route
          path={`${RoutePaths.responsePolicies.pageName}/*`}
          element={
            <CrumbRoute
              title="Response Policies"
              component={ResponsePoliciesRoutes}
            />
          }
        />

        <Route
          path={`${RoutePaths.integrationsResponse.pageName}/*`}
          element={<CrumbRoute component={ResponseIntegrationsRoutes} />}
        />

        <Route
          path={`${RoutePaths.settings.pageName}`}
          element={
            <CrumbRoute
              title="Account Overview"
              component={AccountOverviewPage}
            />
          }
        />

        {config.isLocalRun && (
          <Route
            path={`${RoutePaths.ipTrustLists.pageName}/*`}
            element={<CrumbRoute component={IPTrustListsRoutes} />}
          />
        )}

        {showSubscription && (
          <Route
            path={`${RoutePaths.billing.pageName}`}
            element={<CrumbRoute title="Billing" component={BillingPage} />}
          />
        )}

        <Route
          path="*"
          element={<Navigate to={`${RoutePaths.settings}`} replace />}
        />
      </Routes>
    </SettingsLayout>
  );
};

export default AdminRoutes;
