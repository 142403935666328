import PropTypes from '+prop-types';
import { Fragment, useMemo } from 'react';

import styled from 'styled-components';

import AwsAuthTypes from '@/models/AwsAuthTypes';
import { ContextTypes } from '@/models/ContextTypes';

import ButtonGroupField from '+components/form/ButtonGroupField';
import CheckBox from '+components/form/CheckBox';
import { Field, useFormState } from '+components/form/FinalForm';
import { Label } from '+components/form/FormField';
import Description from '+components/form/FormField/components/Description';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import {
  validateRequired,
  validateS3BucketName,
  validateS3Prefix,
} from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const StyledGroup = styled(Group)`
  margin-left: 100px;
`;

const StyledDescription = styled(Description)`
  margin-left: 140px !important;
`;

const StyledS3Link = styled(Description)`
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-left: 20px !important;
  color: #fff !important;
`;

const AwsS3Fields = ({ disabled, isDnsContext }) => {
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const S3ExampleURL = useMemo(() => {
    return `s3://${formValues.bucket ? formValues.bucket : 'bucket-required'}/${formValues.prefix ? formValues.prefix : ''}${formValues.prefix ? '/' : ''}AWSLogs`;
  }, [formValues]);

  const awsRegionOptions = useProviderRegions('aws');

  const defaultRoleAuth = useMemo(() => {
    return formValues.awsauthtype === 'AccessKey'
      ? AwsAuthTypes.key.value
      : AwsAuthTypes.role.value;
  }, [formValues]);

  return (
    <Fragment>
      {formValues.traffictype === ContextTypes.dns && (
        <Field
          name="vpcid"
          label="VPC ID"
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />
      )}
      <Field
        name="accountid"
        label="AWS Account ID"
        component={TextField}
        type="text"
        validate={validateRequired}
        disabled={disabled}
        required
      />
      <Field
        name="region"
        label="AWS Region"
        component={SelectField}
        validate={validateRequired}
        options={awsRegionOptions}
        parse={normalizeSelectValue}
        disabled={disabled}
        required
      />

      <Field
        name="bucketregion"
        label="S3 Bucket Region"
        component={SelectField}
        validate={validateRequired}
        options={awsRegionOptions}
        parse={normalizeSelectValue}
        helperText="The region of the S3 bucket"
        disabled={disabled}
        required
      />

      <Field
        name="bucket"
        label="S3 Bucket Name"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateS3BucketName}
        helperText={
          <a
            target="_blank"
            href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html"
            rel="noreferrer"
          >
            Bucket naming rules
          </a>
        }
        disabled={disabled}
        required
      />

      <Field
        name="prefix"
        label="Prefix"
        component={TextField}
        type="text"
        maxLength={1024}
        autoComplete="new-password"
        validate={validateS3Prefix}
        helperText="Folder prefix"
        disabled={disabled}
      />

      <Group>
        <Label>S3 Example</Label>
        <StyledS3Link>{S3ExampleURL}</StyledS3Link>
      </Group>

      <Field
        name="sqsurl"
        label="sqs URL"
        component={TextField}
        type="url"
        maxLength={255}
        autoComplete="new-password"
        helperText={`
          If provided, sqs will notify Netography that a new object was written for immediate ingest. 
          e.g. https://sqs.us-east-1.amazonaws.com/123456789012/${
            isDnsContext ? 'query' : 'flow'
          }logq
        `}
        disabled={disabled}
      />

      <StyledGroup>
        <FieldContainer>
          <Field
            name="deleteobjects"
            label="Remove log"
            component={CheckBox}
            type="checkbox"
            disabled={disabled}
          />
        </FieldContainer>
        <StyledDescription>
          Remove log from S3 bucket after processing
        </StyledDescription>
      </StyledGroup>

      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="roleAuth"
          label="Authentication Type"
          component={ButtonGroupField}
          options={Object.values(AwsAuthTypes)}
          disabled={disabled}
          defaultValue={defaultRoleAuth}
        />
        {formValues.roleAuth === AwsAuthTypes.key.value ? (
          <Fragment>
            <Field
              name="accesskeyid"
              label="Access Key ID"
              component={TextField}
              type="text"
              validate={validateRequired}
              helperText="The access key for authenticating to this bucket"
              disabled={disabled}
              required
            />

            <Field
              name="accesssecret"
              label="Access Secret"
              component={TextField}
              type="password"
              autoComplete="new-password"
              validate={validateRequired}
              helperText="The access secret for authenticating to this bucket"
              disabled={disabled}
              required
            />
          </Fragment>
        ) : (
          <Field
            name="role.arn"
            label="AWS ARN"
            component={TextField}
            type="text"
            validate={validateRequired}
            helperText="The role ARN for authenticating to this bucket"
            disabled={disabled}
            required
          />
        )}
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

AwsS3Fields.propTypes = {
  disabled: PropTypes.bool,
  isDnsContext: PropTypes.bool,
};

AwsS3Fields.defaultProps = {
  disabled: false,
  isDnsContext: false,
};

export default AwsS3Fields;
