import { useFlag } from '@unleash/proxy-client-react';

import FeatureFlags from '@/models/FeatureFlags';

import { Breadcrumb } from '+components/Breadcrumb';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';

import DnsClassifications from './components/Dns';
import NetworkClassifications from './components/Network';

const LabeledBreadCrumb = (label) => {
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const prefix = isDnsEnabled ? 'Traffic' : 'Network';

  return <Breadcrumb title={`${prefix} Classifications ${label}`} />;
};

const additionalTabs = [
  {
    label: 'DNS',
    value: 'dns',
    element: <DnsClassifications />,
  },
];

const TrafficClassifications = () => {
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const additionalProps = isDnsEnabled
    ? {
        additionalTabs,
      }
    : {};

  return (
    <EditPageAuditLogTabs
      auditNqlQuery="class == network_classification"
      breadcrumb={LabeledBreadCrumb}
      defaultTabLabel="Network"
      {...additionalProps}
    >
      <NetworkClassifications />
    </EditPageAuditLogTabs>
  );
};

export default TrafficClassifications;
