import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';

import { ContextTypes } from '@/models/ContextTypes';
import FeatureFlags from '@/models/FeatureFlags';

import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import { Breadcrumb } from '+components/Breadcrumb';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import nqlLang from '+utils/nqlLang';

import { excludeMetrics } from '../../utils';
import SourcesLineChart from '../SourcesLineChart';
import MessageDetailsModal from './MessageDetailsModal';
import VpcStatusTable from './VpcStatusTable';

const nameFields = {
  [ContextTypes.dns]: 'datasrc',
};

const VpcDetails = () => {
  const dispatch = useDispatch();
  const { vpcid } = useParams();
  const [detailsModalOpen, toggleDetailsModal] = useToggle(false);
  const [detailsMessage, setDetailsMessage] = useState('');
  const vpc = useSelector(vpcSelectors.getVpcById(vpcid));
  const sources = useMemo(() => (vpc ? [vpc] : []), [vpc]);
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const [filters] = useGlobalFilters();

  const excludeContexts = useMemo(
    () =>
      vpc?.traffictype === ContextTypes.flow
        ? new Set([
            ContextTypes.alerts,
            ContextTypes.blocks,
            ContextTypes.traffic,
            ContextTypes.dns,
          ])
        : new Set([
            ContextTypes.alerts,
            ContextTypes.blocks,
            ContextTypes.traffic,
            ContextTypes.flow,
          ]),
    [vpc?.traffictype],
  );

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: vpc?.traffictype || ContextTypes.flow,
  });

  const nqlQuery = useMemo(
    () =>
      vpc?.name
        ? nqlLang.equal(nameFields[vpc?.traffictype] || 'flowsrcname', vpc.name)
        : '',
    [vpc],
  );

  useEffect(() => {
    dispatch(vpcActions.fetchById(vpcid));
  }, [vpcid, filters.refresher]);

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  return !Object.keys(vpc || {}).length ? null : (
    <Fragment>
      <Breadcrumb
        title={`${isDnsEnabled ? 'Traffic' : 'Flow'} Source Details — ${
          vpc?.name || 'unknown'
        }`}
      />
      <MessageDetailsModal
        isOpen={detailsModalOpen}
        toggleModal={toggleDetailsModal}
        message={detailsMessage}
      />
      <GlobalFiltersSetting
        context={context}
        excludeMetrics={excludeMetrics}
        excludeContexts={excludeContextsArr}
        nql
        metric
        socketControl={false}
      />
      <SourcesLineChart
        sources={sources}
        nqlQuery={nqlQuery}
        context={context}
      />
      <VpcStatusTable
        vpc={vpc}
        toggleModal={toggleDetailsModal}
        setDetailsMessage={setDetailsMessage}
      />
    </Fragment>
  );
};

export default VpcDetails;
