import PropTypes from '+prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation } from 'react-router-dom';

import styled, { StyleSheetManager, ThemeProvider } from 'styled-components';

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

import SettingCategories from '@/models/SettingCategories';
import { ThemeTypes } from '@/models/ThemeTypes';

import PickerGlobalStyle from '+components/form/DateTimePicker/PickerGlobalStyle';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';
import { darkTheme, GlobalStyles, lightTheme } from '+theme/index';
import timeFormatSwitch from '+utils/timeFormatSwitch';

const Wrapper = styled.div``;

const shouldForwardProp = (/* prop */) => {
  // console.log('shouldForwardProp', prop);
  return true;
};

const MainWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const scrollRef = useRef();
  const [theme] = usePortalSettingsValue(
    SettingCategories.ui,
    'theme',
    ThemeTypes.dark,
  );
  const [localTheme] = useUIProperty('theme');
  const [isTimeFormat12h] = usePortalSettingsValue(
    SettingCategories.ui,
    'isTimeFormat12h',
    false,
  );

  useEffect(() => {
    timeFormatSwitch(isTimeFormat12h);
  }, [isTimeFormat12h]);

  useEffect(() => {
    const scrollElement = scrollRef.current?.getScrollElement();
    scrollElement?.scrollTo?.({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const scrollElement = scrollRef.current?.getScrollElement();
    scrollElement?.scrollTo?.({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  const className = localTheme || theme;
  const styledTheme = className === ThemeTypes.dark ? darkTheme : lightTheme;
  const muiTheme = useMemo(() => {
    const { material, ...rest } = styledTheme;

    return {
      ...rest,
      ...createTheme(material),
    };
  }, [styledTheme]);

  return (
    <StyleSheetManager
      enableVendorPrefixes
      shouldForwardProp={shouldForwardProp}
    >
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={muiTheme}>
          <DndProvider backend={HTML5Backend}>
            <GlobalStyles />
            <PickerGlobalStyle />
            <Wrapper className={className}>{children}</Wrapper>
          </DndProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyleSheetManager>
  );
};

MainWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainWrapper;
