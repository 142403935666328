/* eslint-disable react/prop-types */
import PropTypes from '+prop-types';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { actions as bgpActions } from '@/redux/api/bgp';

import Alert from '+components/Alert';
import CheckBoxField from '+components/form/CheckBox';
import FieldsCol from '+components/form/FieldsCol';
import FieldsRow from '+components/form/FieldsRow';
import { Field, useFormState } from '+components/form/FinalForm';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import { normalizeSelectValue } from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import { validateIp, validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';

const prefixName = (name, prefix) => (prefix ? `${prefix}_${name}` : name);

const StyledToggleField = styled(ToggleField)`
  margin-bottom: unset;
`;

export const BgpFields = (props) => {
  const {
    inputPrefix,
    values = {},
    required = true,
    disabled = false,
    device = [],
  } = props;

  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const isEdit = !!values.id;

  return (
    <Fragment>
      {isEdit && (
        <Group>
          <Label>Remote BGP</Label>
          <Plaintext>{values.addressremote}</Plaintext>
        </Group>
      )}

      {!isEdit && (
        <Field
          style={{ width: '50%' }}
          name={prefixName('addressremote', inputPrefix)}
          label="Remote BGP"
          helperText="IPv4 Address of your router."
          type="text"
          maxLength={50}
          component={SelectField}
          options={device.ips}
          freeSolo
          parse={normalizeSelectValue}
          validate={required ? [validateRequired, validateIp] : validateIp}
          required={required}
          disabled={disabled}
        />
      )}

      <Field
        name={prefixName('description', inputPrefix)}
        label="Description"
        helperText="Description of this session."
        type="text"
        maxLength={255}
        component={TextField}
        validate={required ? validateRequired : null}
        autoComplete="new-password"
        required={required}
        disabled={disabled}
      />

      <Field
        style={{ width: '35%' }}
        name={prefixName('asn', inputPrefix)}
        label="ASN"
        helperText="Autonomous System Number."
        type="number"
        min={1}
        max={4294967295}
        component={TextField}
        validate={required ? validateRequired : null}
        autoComplete="new-password"
        required={required}
        disabled={disabled}
      />

      <Group>
        <FieldContainer>
          <Field
            name={prefixName('admindown', inputPrefix)}
            label="Admin Down"
            helperText="Is the neighbor administrivally down?"
            type="checkbox"
            component={CheckBoxField}
            disabled={disabled}
          />
        </FieldContainer>
      </Group>

      <Group>
        <FieldContainer>
          <Field
            name={prefixName('gracefulrestart', inputPrefix)}
            label="Graceful Restart"
            helperText="Negotiate graceful restart with neighbor."
            type="checkbox"
            component={CheckBoxField}
            disabled={disabled}
          />
        </FieldContainer>
      </Group>

      <Field
        style={{ width: '35%' }}
        name={prefixName('keepalive', inputPrefix)}
        label="Keep Alive"
        helperText="In Seconds. Default is 60 if left blank."
        type="number"
        min={0}
        component={TextField}
        autoComplete="new-password"
        disabled={disabled}
      />

      <Field
        style={{ width: '35%' }}
        name={prefixName('holdtime', inputPrefix)}
        label="Hold Time"
        helperText="In Seconds. Default is 180 or Three times (3x) Keep Alive if left blank."
        type="number"
        min={0}
        max={255}
        component={TextField}
        autoComplete="new-password"
        disabled={disabled}
      />

      <Field
        style={{ width: '35%' }}
        name={prefixName('restarttime', inputPrefix)}
        label="Restart Time"
        helperText="In Seconds. Default is equilivant to the Hold Time or 180 if left blank."
        type="number"
        min={0}
        component={TextField}
        autoComplete="new-password"
        disabled={disabled}
      />
      <RoleAuthFieldsSection label="Authentication">
        <Field
          name={prefixName('authpassword', inputPrefix)}
          label="Auth Password"
          helperText="MD5 password used on the BGP connection."
          type="password"
          autoComplete="new-password"
          maxLength={32}
          component={TextField}
          disabled={disabled}
        />
      </RoleAuthFieldsSection>
      <RoleAuthFieldsSection label="Advanced Settings">
        <Field
          name={prefixName('advanced', inputPrefix)}
          component={StyledToggleField}
          type="checkbox"
          label="Override Capabilities"
          disabled={disabled}
        />

        {formValues[prefixName('advanced', inputPrefix)] && (
          <Fragment>
            <Alert severity="warning" sx={{ marginBottom: '15px' }}>
              Capabilities are automatically negotiated. Once you manually
              enable/disable capabilities they will no longer be negotiated.
            </Alert>

            <FieldsRow>
              <FieldsCol>
                <Group>
                  <FieldContainer>
                    <Field
                      name={prefixName('ipv4-flowspec', inputPrefix)}
                      component={ToggleField}
                      type="checkbox"
                      checkedLabel="ipv4-flowspec"
                      disabled={disabled}
                    />
                  </FieldContainer>
                </Group>

                <Group>
                  <FieldContainer>
                    <Field
                      name={prefixName('ipv4-unicast', inputPrefix)}
                      component={ToggleField}
                      type="checkbox"
                      checkedLabel="ipv4-unicast"
                      disabled={disabled}
                    />
                  </FieldContainer>
                </Group>
              </FieldsCol>

              <FieldsCol>
                <Group>
                  <Field
                    name={prefixName('ipv6-flowspec', inputPrefix)}
                    component={ToggleField}
                    type="checkbox"
                    checkedLabel="ipv6-flowspec"
                    disabled={disabled}
                  />
                </Group>

                <Group>
                  <Field
                    name={prefixName('ipv6-unicast', inputPrefix)}
                    component={ToggleField}
                    type="checkbox"
                    checkedLabel="ipv6-unicast"
                    disabled={disabled}
                  />
                </Group>
              </FieldsCol>
            </FieldsRow>
          </Fragment>
        )}
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

const BgpFormModal = (props) => {
  const { device, item, isOpen, toggleModal, inputPrefix, disabled, ...tail } =
    props;
  const dispatch = useDispatch();

  const isEdit = !!item.id;

  const onSubmit = (values) => {
    const keepalive = prefixName('keepalive', inputPrefix);
    const holdtime = prefixName('holdtime', inputPrefix);
    const restarttime = prefixName('restarttime', inputPrefix);

    if (values[keepalive] && values[keepalive] >= 0) {
      values[keepalive] = parseInt(values[keepalive], 10);
    } else {
      values[keepalive] = null;
    }

    if (values[holdtime] && values[holdtime] >= 0) {
      values[holdtime] = parseInt(values[holdtime], 10);
    } else {
      values[holdtime] = null;
    }

    if (values[restarttime] && values[restarttime] >= 0) {
      values[restarttime] = parseInt(values[restarttime], 10);
    } else {
      values[restarttime] = null;
    }

    if (item.id) {
      dispatch(
        bgpActions.updateNeighbor({
          ...item,
          ...values,
          deviceid: device?.id,
        }),
      );
    } else {
      dispatch(
        bgpActions.addNeighbor({
          ...values,
          deviceid: device?.id,
        }),
      );
    }

    toggleModal();
  };

  return (
    <FormModal
      {...tail}
      mode={isEdit ? 'edit' : 'add'}
      item="BGP neighbor"
      isOpen={isOpen}
      initialValues={item}
      disabled={disabled}
      onSubmit={onSubmit}
      onToggle={toggleModal}
    >
      <BgpFields values={item} disabled={disabled} device={device} />
    </FormModal>
  );
};

BgpFormModal.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
  }),
  inputPrefix: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};

BgpFormModal.defaultProps = {
  item: {},
  inputPrefix: null,
  isOpen: false,
};

export default BgpFormModal;
