import { lazy } from 'react';

import ChartAreasplineVariantIcon from 'mdi-react/ChartAreasplineVariantIcon';
import ChartBarStacked from 'mdi-react/ChartBarStackedIcon';
import ChartDonutIcon from 'mdi-react/ChartDonutIcon';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import ChartPieIcon from 'mdi-react/ChartPieIcon';
import ChartSankeyIcon from 'mdi-react/ChartSankeyIcon';
import ChartScatterPlotIcon from 'mdi-react/ChartScatterPlotIcon';
import ChartTreeIcon from 'mdi-react/ChartTreeIcon';
import FamilyTreeIcon from 'mdi-react/FamilyTreeIcon';
import GaugeIcon from 'mdi-react/GaugeIcon';
import GraphOutlineIcon from 'mdi-react/GraphOutlineIcon';
import MapIcon from 'mdi-react/MapIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import PollIcon from 'mdi-react/PollIcon';
import PulseIcon from 'mdi-react/PulseIcon';
import SurroundSound31Icon from 'mdi-react/SurroundSound31Icon';
import TableIcon from 'mdi-react/TableIcon';
import TableRowIcon from 'mdi-react/TableRowIcon';
import TimetableIcon from 'mdi-react/TimetableIcon';

import { ChartTypes } from '@/models/ChartTypes';
import StatsRequest from '@/models/StatsRequest';
import { WidgetCategories } from '@/models/WidgetCategories';
import { WidgetTypes } from '@/models/WidgetTypes';

import { config } from '@/config';

import { includeFields as includeFieldsAttackSurface } from '+components/charts/AttackSurface';
import { includeFields as includeBlockWorldMap } from '+components/charts/BlockWorldMap';
import { includeFields as includeEventWorldMap } from '+components/charts/EventWorldMap';
import { includeFields as includeFlowWorldMap } from '+components/charts/FlowWorldMap';
import { includeFields as includeFieldsIpExplorer } from '+components/IpExplorer';

import { ipExplorerDefaultSearch } from './renderers/ipExplorerDefaultSearch';
import { makeSearchRequest, makeStatsRequest } from './requests';

const AreaChartRenderer = lazy(() => import('./renderers/AreaChartRenderer'));
const AttackSurfaceChartRenderer = lazy(
  () => import('./renderers/AttackSurfaceChartRenderer'),
);
const AuditLogTableRenderer = lazy(
  () => import('./renderers/AuditLogTableRenderer'),
);
const BarChartRaceRenderer = lazy(
  () => import('./renderers/BarChartRaceRenderer'),
);
const BarChartRenderer = lazy(() => import('./renderers/BarChartRenderer'));
const BlockTableRenderer = lazy(() => import('./renderers/BlockTableRenderer'));
const BlockWorldMapRenderer = lazy(
  () => import('./renderers/BlockWorldMapRenderer'),
);
const CustomTableRenderer = lazy(
  () => import('./renderers/CustomTableRenderer'),
);
const EventTableRenderer = lazy(() => import('./renderers/EventTableRenderer'));
const EventWorldMapRenderer = lazy(
  () => import('./renderers/EventWorldMapRenderer'),
);
const FlowTableRenderer = lazy(() => import('./renderers/FlowTableRenderer'));
const DnsTableRenderer = lazy(() => import('./renderers/DnsTableRenderer'));
const FlowWorldMapRenderer = lazy(
  () => import('./renderers/FlowWorldMapRenderer'),
);
const TrafficTableRenderer = lazy(
  () => import('./renderers/TrafficTableRenderer'),
);
const GaugeChartRenderer = lazy(() => import('./renderers/GaugeChartRenderer'));
const HeatmapChartRenderer = lazy(
  () => import('./renderers/HeatmapChartRenderer'),
);
const IpExplorerChartRenderer = lazy(
  () => import('./renderers/IpExplorerChartRenderer'),
);
const LineChartRenderer = lazy(() => import('./renderers/LineChartRenderer'));
const MarkdownRenderer = lazy(() => import('./renderers/MarkdownRenderer'));
const MultipleChartRenderer = lazy(
  () => import('./renderers/MultipleChartRenderer'),
);
const PieChartRenderer = lazy(() => import('./renderers/PieChartRenderer'));
const SankeyChartRenderer = lazy(
  () => import('./renderers/SankeyChartRenderer'),
);
const ScatterPlotChartRenderer = lazy(
  () => import('./renderers/ScatterPlotChartRenderer'),
);
const SingleStackedBarChartRenderer = lazy(
  () => import('./renderers/SingleStackedBarChartRenderer'),
);
const SparklineChartRenderer = lazy(
  () => import('./renderers/SparklineChartRenderer'),
);
const SpiralChartRenderer = lazy(
  () => import('./renderers/SpiralChartRenderer'),
);
const StackedBarChartRenderer = lazy(
  () => import('./renderers/StackedBarChartRenderer'),
);
const ValueChartRenderer = lazy(() => import('./renderers/ValueChartRenderer'));
const MultipleWrapper = lazy(() => import('./wrappers/MultipleWrapper'));
const NoRequestWrapper = lazy(() => import('./wrappers/NoRequestWrapper'));
const SearchWrapper = lazy(() => import('./wrappers/SearchWrapper'));
const StatsWrapper = lazy(() => import('./wrappers/StatsWrapper'));
const ValueWrapper = lazy(() => import('./wrappers/ValueWrapper'));

const defaultLayout = {
  w: 4,
  h: 6,
  minW: 1,
  minH: 2,
};

export const maxTsFieldCount = 10;
export const maxAggFieldCount = 7;

export const widgets = {
  // This is a special widget type that is used to render the multiple widget chart
  // It is not a real widget type and should not be used in the widget list
  [WidgetTypes.Multiple]: {
    wrappers: {
      [WidgetCategories.alerts]: MultipleWrapper,
      [WidgetCategories.audit]: MultipleWrapper,
      [WidgetCategories.blocks]: MultipleWrapper,
      [WidgetCategories.flow]: MultipleWrapper,
      [WidgetCategories.dns]: MultipleWrapper,
    },
    renderer: MultipleChartRenderer,
    disabled: true,
  },
  [WidgetTypes.Area]: {
    icon: <ChartAreasplineVariantIcon />,
    label: 'area chart',
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: ['counts'],
    maxFieldCount: maxTsFieldCount,
    minSize: 0,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.ts,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: AreaChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 6,
    },
    canBeCombinedWith: [WidgetTypes.Area, WidgetTypes.Line, WidgetTypes.Bar],
  },
  [WidgetTypes.AttackSurface]: {
    icon: <FamilyTreeIcon style={{ transform: 'rotate(-90deg)' }} />,
    label: 'Attack Surface',
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: ['card', 'counts'],
    minPortCount: 5,
    maxPortCount: 50,
    requestGenerator: (props) => ({
      ...makeSearchRequest(props),
      include: includeFieldsAttackSurface,
    }),
    wrappers: {
      [WidgetCategories.flow]: SearchWrapper,
    },
    renderer: AttackSurfaceChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 12,
    },
  },
  [WidgetTypes.Line]: {
    icon: <ChartLineIcon />,
    label: 'line chart',
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: ['counts'],
    maxFieldCount: maxTsFieldCount,
    minSize: 0,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.ts,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: LineChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 6,
    },
    canBeCombinedWith: [WidgetTypes.Area, WidgetTypes.Line, WidgetTypes.Bar],
  },
  [WidgetTypes.ScatterPlot]: {
    icon: <ChartScatterPlotIcon />,
    label: 'scatter plot chart',
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: ['counts'],
    maxFieldCount: maxTsFieldCount,
    minSize: 0,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.ts,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: ScatterPlotChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 6,
    },
  },
  [WidgetTypes.Spiral]: {
    icon: <ChartDonutIcon />,
    label: 'spiral chart',
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: ['card', 'counts'],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.ts,
    wrappers: {
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: SpiralChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 12,
    },
  },
  [WidgetTypes.Bar]: {
    icon: <PollIcon className="rotate" />,
    label: 'bar chart',
    chartType: ChartTypes.bar,
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: [],
    excludeMetricForAggMinSize: ['counts'],
    maxFieldCount: maxAggFieldCount,
    minSize: 0,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.agg,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: BarChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 6,
    },
  },
  [WidgetTypes.StackedBar]: {
    icon: <ChartBarStacked />,
    label: 'stacked bar chart',
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: ['counts'],
    maxFieldCount: maxTsFieldCount,
    minSize: 0,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.ts,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: StackedBarChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 6,
    },
  },
  [WidgetTypes.SingleStackedBar]: {
    icon: <TableRowIcon />,
    label: 'single bar chart',
    chartType: ChartTypes.bar,
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: [],
    excludeMetricForAggMinSize: ['counts'],
    maxFieldCount: maxAggFieldCount,
    minSize: 0,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.agg,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: SingleStackedBarChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 4,
    },
  },
  [WidgetTypes.Pie]: {
    icon: <ChartPieIcon />,
    label: 'pie chart',
    chartType: ChartTypes.pie,
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: [],
    excludeMetricForAggMinSize: ['counts'],
    maxFieldCount: maxAggFieldCount,
    minSize: 0,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.agg,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: PieChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 3,
      h: 10,
    },
  },
  [WidgetTypes.Heatmap]: {
    icon: <ChartTreeIcon />,
    label: 'heatmap chart',
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: ['card'],
    maxFieldCount: 2,
    minSize: 1,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.heatmap,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: HeatmapChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 12,
      h: 14,
    },
  },
  [WidgetTypes.Sankey]: {
    icon: <ChartSankeyIcon />,
    label: 'sankey chart',
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: ['card'],
    defaultMetric: 'counts',
    maxFieldCount: 1,
    minSize: 1,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.sankey,
    wrappers: {
      [WidgetCategories.flow]: StatsWrapper,
    },
    renderer: SankeyChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 12,
      h: 14,
    },
  },
  [WidgetTypes.FlowMap]: {
    icon: <MapIcon />,
    label: 'flows map',
    chartType: ChartTypes.map,
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: [],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: (props) => ({
      ...makeSearchRequest(props),
      include: includeFlowWorldMap,
    }),
    wrappers: {
      [WidgetCategories.flow]: SearchWrapper,
    },
    renderer: FlowWorldMapRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 6,
      h: 8,
    },
    maxNqlQueries:
      StatsRequest.SearchConfig[WidgetCategories.flow]?.maxNqlQueries ?? 1,
    maxIntersects:
      StatsRequest.SearchConfig[WidgetCategories.flow]?.maxIntersects ?? 0,
  },
  [WidgetTypes.EventMap]: {
    icon: <MapIcon />,
    label: 'events map',
    chartType: ChartTypes.map,
    excludeContext: [
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.flow,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: [],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: (props) => ({
      ...makeSearchRequest(props),
      include: includeEventWorldMap,
    }),
    wrappers: {
      [WidgetCategories.alerts]: SearchWrapper,
    },
    renderer: EventWorldMapRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 6,
      h: 8,
    },
  },
  [WidgetTypes.BlockMap]: {
    icon: <MapIcon />,
    label: 'blocks map',
    chartType: ChartTypes.map,
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.flow,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: [],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: (props) => ({
      ...makeSearchRequest(props),
      include: includeBlockWorldMap,
    }),
    wrappers: {
      [WidgetCategories.blocks]: SearchWrapper,
    },
    renderer: BlockWorldMapRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 6,
      h: 8,
    },
  },
  [WidgetTypes.Sparkline]: {
    icon: <PulseIcon />,
    label: 'sparkline chart',
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: ['counts'],
    maxFieldCount: maxTsFieldCount,
    minSize: 0,
    maxSize: 1,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.ts,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: SparklineChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 2,
      h: 2,
    },
  },
  [WidgetTypes.Gauge]: {
    icon: <GaugeIcon />,
    label: 'gauge chart',
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: ['counts'],
    maxFieldCount: maxAggFieldCount,
    minSize: 0,
    maxSize: 1,
    generateSummary: true,
    requestGenerator: makeStatsRequest,
    wrappers: {
      [WidgetCategories.alerts]: ValueWrapper,
      [WidgetCategories.audit]: ValueWrapper,
      [WidgetCategories.blocks]: ValueWrapper,
      [WidgetCategories.flow]: ValueWrapper,
      [WidgetCategories.dns]: ValueWrapper,
    },
    renderer: GaugeChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 2,
      h: 4,
    },
  },
  [WidgetTypes.Value]: {
    icon: <SurroundSound31Icon />,
    label: 'single value',
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: ['counts'],
    maxFieldCount: maxAggFieldCount,
    minSize: 0,
    maxSize: 1,
    generateSummary: true,
    requestGenerator: makeStatsRequest,
    wrappers: {
      [WidgetCategories.alerts]: ValueWrapper,
      [WidgetCategories.audit]: ValueWrapper,
      [WidgetCategories.blocks]: ValueWrapper,
      [WidgetCategories.flow]: ValueWrapper,
      [WidgetCategories.dns]: ValueWrapper,
    },
    renderer: ValueChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 2,
      h: 4,
    },
  },
  [WidgetTypes.FlowTable]: {
    icon: <TimetableIcon />,
    label: 'flows table',
    chartType: ChartTypes.table,
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: [],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: makeSearchRequest,
    wrappers: {
      [WidgetCategories.flow]: SearchWrapper,
    },
    renderer: FlowTableRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 12,
      h: 24,
    },
    maxNqlQueries:
      StatsRequest.SearchConfig[WidgetCategories.flow]?.maxNqlQueries ?? 1,
    maxIntersects:
      StatsRequest.SearchConfig[WidgetCategories.flow]?.maxIntersects ?? 0,
  },
  [WidgetTypes.DnsTable]: {
    icon: <TimetableIcon />,
    label: 'dns table',
    chartType: ChartTypes.table,
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.flow,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: [],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: makeSearchRequest,
    wrappers: {
      [WidgetCategories.dns]: SearchWrapper,
    },
    renderer: DnsTableRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 12,
      h: 24,
    },
    maxNqlQueries:
      StatsRequest.SearchConfig[WidgetCategories.dns]?.maxNqlQueries ?? 1,
    maxIntersects:
      StatsRequest.SearchConfig[WidgetCategories.dns]?.maxIntersects ?? 0,
  },
  [WidgetTypes.EventTable]: {
    icon: <TimetableIcon />,
    label: 'events table',
    chartType: ChartTypes.table,
    excludeContext: [
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.flow,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: [],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: makeSearchRequest,
    wrappers: {
      [WidgetCategories.alerts]: SearchWrapper,
    },
    renderer: EventTableRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 12,
      h: 24,
    },
  },
  [WidgetTypes.AuditLogTable]: {
    icon: <TimetableIcon />,
    label: 'audit logs table',
    chartType: ChartTypes.table,
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.blocks,
      WidgetCategories.flow,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: [],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: makeSearchRequest,
    wrappers: {
      [WidgetCategories.audit]: SearchWrapper,
    },
    renderer: AuditLogTableRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 12,
      h: 24,
    },
  },
  [WidgetTypes.BlockTable]: {
    icon: <TimetableIcon />,
    label: 'blocks table',
    chartType: ChartTypes.table,
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.flow,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: [],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: makeSearchRequest,
    wrappers: {
      [WidgetCategories.blocks]: SearchWrapper,
    },
    renderer: BlockTableRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 12,
      h: 24,
    },
  },
  [WidgetTypes.Table]: {
    icon: <TableIcon />,
    label: 'custom table',
    chartType: ChartTypes.table,
    excludeContext: [WidgetCategories.other, WidgetCategories.traffic],
    excludeMetric: [],
    maxFieldCount: maxAggFieldCount,
    minSize: 1,
    maxSize: 5000,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.agg,
    requestFormat: 'keymap',
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.audit]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
      [WidgetCategories.traffic]: StatsWrapper,
    },
    renderer: CustomTableRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 8,
    },
  },
  [WidgetTypes.TrafficTable]: {
    icon: <TimetableIcon />,
    label: 'traffic table',
    chartType: ChartTypes.table,
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.flow,
      WidgetCategories.other,
      WidgetCategories.dns,
    ],
    excludeMetric: [],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: makeSearchRequest,
    wrappers: {
      [WidgetCategories.traffic]: SearchWrapper,
    },
    renderer: TrafficTableRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 12,
      h: 24,
    },
    maxNqlQueries:
      StatsRequest.SearchConfig[WidgetCategories.traffic]?.maxNqlQueries ?? 1,
    maxIntersects:
      StatsRequest.SearchConfig[WidgetCategories.traffic]?.maxIntersects ?? 0,
  },
  [WidgetTypes.ForceDirected]: {
    icon: <GraphOutlineIcon />,
    label: 'IP explorer',
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.dns,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: ['card', 'counts'],
    maxFieldCount: 0,
    minSize: 1,
    requestGenerator: (props) => ({
      ...makeSearchRequest(props),
      include: includeFieldsIpExplorer,
    }),
    additionalSearch: ipExplorerDefaultSearch,
    wrappers: {
      [WidgetCategories.flow]: SearchWrapper,
    },
    renderer: IpExplorerChartRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 4,
      h: 12,
    },
  },
  [WidgetTypes.Markdown]: {
    icon: <PencilIcon />,
    label: 'Markdown',
    excludeContext: [
      WidgetCategories.alerts,
      WidgetCategories.audit,
      WidgetCategories.blocks,
      WidgetCategories.flow,
      WidgetCategories.dns,
      WidgetCategories.traffic,
    ],
    excludeMetrics: [],
    maxFieldCount: 0,
    minSize: 1,
    renderer: MarkdownRenderer,
    wrappers: {
      [WidgetCategories.other]: NoRequestWrapper,
    },
    defaultLayout: {
      ...defaultLayout,
      w: 5,
      h: 10,
    },
  },
  [WidgetTypes.BarChartRace]: {
    icon: <PollIcon className="rotate" />,
    label: 'Bar Chart Race',
    excludeContext: [
      WidgetCategories.audit,
      WidgetCategories.other,
      WidgetCategories.traffic,
    ],
    excludeMetric: ['counts'],
    maxFieldCount: maxTsFieldCount,
    minSize: 0,
    maxSize: 50,
    requestGenerator: makeStatsRequest,
    requestType: StatsRequest.Types.ts,
    wrappers: {
      [WidgetCategories.alerts]: StatsWrapper,
      [WidgetCategories.blocks]: StatsWrapper,
      [WidgetCategories.flow]: StatsWrapper,
      [WidgetCategories.dns]: StatsWrapper,
    },
    renderer: BarChartRaceRenderer,
    defaultLayout: {
      ...defaultLayout,
      w: 10,
      h: 6,
    },
  },
};

const isMetricDisabled = new Set([
  WidgetTypes.EventMap,
  WidgetTypes.BlockMap,
  WidgetTypes.FlowMap,
  WidgetTypes.EventTable,
  WidgetTypes.AuditLogTable,
  WidgetTypes.BlockTable,
  WidgetTypes.FlowTable,
  WidgetTypes.DnsTable,
  WidgetTypes.TrafficTable,
  WidgetTypes.ForceDirected,
  WidgetTypes.Markdown,
  WidgetTypes.AttackSurface,
]);

const isLegendDisabled = new Set([
  WidgetTypes.Sankey,
  WidgetTypes.Sparkline,
  WidgetTypes.Gauge,
  WidgetTypes.Value,
  WidgetTypes.Table,
  WidgetTypes.EventMap,
  WidgetTypes.BlockMap,
  WidgetTypes.FlowMap,
  WidgetTypes.EventTable,
  WidgetTypes.AuditLogTable,
  WidgetTypes.BlockTable,
  WidgetTypes.FlowTable,
  WidgetTypes.DnsTable,
  WidgetTypes.TrafficTable,
  WidgetTypes.Heatmap,
  WidgetTypes.Markdown,
  WidgetTypes.AttackSurface,
]);

const isLegendDisabledPosition = new Set([
  WidgetTypes.Spiral,
  WidgetTypes.Sparkline,
  WidgetTypes.Gauge,
  WidgetTypes.Value,
  WidgetTypes.ForceDirected,
  WidgetTypes.Markdown,
  WidgetTypes.AttackSurface,
]);

const isTrendEnabled = new Set([WidgetTypes.Gauge, WidgetTypes.Value]);

const isFillAreaEnabled = new Set([
  WidgetTypes.Gauge,
  WidgetTypes.Value,
  WidgetTypes.Sparkline,
]);

const isSeveritiesEnabled = new Set([
  WidgetTypes.EventMap,
  WidgetTypes.EventTable,
]);

const isOrientationEnabled = new Set([
  WidgetTypes.Bar,
  WidgetTypes.SingleStackedBar,
  WidgetTypes.Sankey,
]);

const isPaletteColorPickerDisabled = new Set([
  WidgetTypes.Spiral,
  WidgetTypes.Value,
  WidgetTypes.EventMap,
  WidgetTypes.Table,
  WidgetTypes.FlowTable,
  WidgetTypes.DnsTable,
  WidgetTypes.EventTable,
  WidgetTypes.BlockTable,
  WidgetTypes.TrafficTable,
  WidgetTypes.AuditLogTable,
  WidgetTypes.ForceDirected,
  WidgetTypes.Markdown,
  WidgetTypes.AttackSurface,
]);

const isSeriesColorPickerDisabled = new Set([
  WidgetTypes.Spiral,
  WidgetTypes.Heatmap,
  WidgetTypes.FlowMap,
  WidgetTypes.EventMap,
  WidgetTypes.BlockMap,
  WidgetTypes.Table,
  WidgetTypes.FlowTable,
  WidgetTypes.DnsTable,
  WidgetTypes.TrafficTable,
  WidgetTypes.EventTable,
  WidgetTypes.BlockTable,
  WidgetTypes.AuditLogTable,
  WidgetTypes.ForceDirected,
  WidgetTypes.Markdown,
  WidgetTypes.AttackSurface,
]);

const isValueColorPickerEnabled = new Set([WidgetTypes.Gauge]);

const isStackedEnabled = new Set([WidgetTypes.Area, WidgetTypes.Line]);

const isNqlDisabled = new Set([WidgetTypes.Markdown]);

const isDateTimeDisabled = new Set([WidgetTypes.Markdown]);

const isSubAccountAggregationDisabled = new Set([
  // WidgetTypes.AttackSurface,
  // WidgetTypes.FlowMap,
  // WidgetTypes.EventMap,
  // WidgetTypes.BlockMap,
  // WidgetTypes.FlowTable,
  // WidgetTypes.DnsTable,
  // WidgetTypes.EventTable,
  // WidgetTypes.AuditLogTable,
  // WidgetTypes.BlockTable,
  // WidgetTypes.ForceDirected,
]);

const isHideSubAccountEnabled = new Set([WidgetTypes.Sankey]);

// allowed only for development
if (config.environment !== 'development') {
  delete widgets[WidgetTypes.AttackSurface];
}

Object.keys(widgets).forEach((key) => {
  const widget = widgets[key];

  widget.value = key;
  widget.isMetricEnabled = !isMetricDisabled.has(key);
  widget.isLegendEnabled = !isLegendDisabled.has(key);
  widget.isLegendPositionEnabled = !isLegendDisabledPosition.has(key);
  widget.isTrendEnabled = isTrendEnabled.has(key);
  widget.isFillAreaEnabled = isFillAreaEnabled.has(key);
  widget.isSeveritiesEnabled = isSeveritiesEnabled.has(key);
  widget.isOrientationEnabled = isOrientationEnabled.has(key);
  widget.isPaletteColorPickerEnabled = !isPaletteColorPickerDisabled.has(key);
  widget.isSeriesColorPickerEnabled = !isSeriesColorPickerDisabled.has(key);
  widget.isValueColorPickerEnabled = isValueColorPickerEnabled.has(key);
  widget.isStackedEnabled = isStackedEnabled.has(key);
  widget.isNqlEnabled = !isNqlDisabled.has(key);
  widget.isDateTimeEnabled = !isDateTimeDisabled.has(key);
  widget.isSubAccountAggregationEnabled =
    !isSubAccountAggregationDisabled.has(key);
  widget.isHideSubAccountEnabled = isHideSubAccountEnabled.has(key);
});
