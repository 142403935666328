import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';

import { ContextTypes } from '@/models/ContextTypes';
import FeatureFlags from '@/models/FeatureFlags';
import * as PropertiesTray from '@/models/PropertiesTray';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as ruleActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import Table from '+components/Table';
import { MenuColumnPropertiesTrayTrigger } from '+components/Table/Columns';
import { useAvailableExtractor } from '+hooks/useAvailableExtractor';
import { usePreparedColumns } from '+hooks/usePreparedColumns';
import { getColumnsHelper } from '+utils/getColumnsHelper';

import { Columns, columnsCollection } from './components/Columns';
import SubDetails, { getRowHeight } from './components/SubDetails';

const subtractedFields = ['ipinfo'];

export const defaultColumns = [
  // Columns.id,
  Columns.timestamp,
  Columns.severity,
  Columns.summary,
  Columns.categories,
  Columns.flowsrcnames,
  Columns.alerttype,
  Columns.duration,
  Columns.menu,
];

const defaultSortBy = [
  {
    id: Columns.alerttype,
    desc: true,
  },
  {
    id: Columns.severity,
    desc: true,
  },
  {
    id: Columns.timestamp,
    desc: true,
  },
];

const EventTable = (props) => {
  const {
    columns: columnsProp,
    tableData: tableDataProp,
    data,
    noDataText,
    severities,
    ...tail
  } = props;
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const algorithms = useSelector(rulesSelectors.getAlgorithms);
  const categories = useSelector(rulesSelectors.getCategories);

  const columns = useMemo(() => {
    const tempColumns = columnsProp;
    if (customer?.multi_account) {
      const indexOfCustomerColumn = columnsProp.indexOf(Columns.customer);
      if (indexOfCustomerColumn === -1) {
        tempColumns.push(Columns.customer);
      }
    }

    if (isDnsEnabled) {
      const indexOfTrafficTypeColumn = columnsProp.indexOf(Columns.traffictype);
      if (indexOfTrafficTypeColumn === -1) {
        tempColumns.splice(1, 0, Columns.traffictype);
      }
    }
    return tempColumns;
  }, [columnsProp, customer, isDnsEnabled]);

  const algorithmsHash = useMemo(
    () =>
      Object.values(algorithms || {}).reduce(
        (acc, item) => ({ ...acc, [item.name]: item }),
        {},
      ),
    [algorithms],
  );

  const categoriesHash = useMemo(
    () =>
      categories?.reduce((acc, item) => ({ ...acc, [item.name]: item }), {}),
    [categories],
  );

  const cxActionMenu = useCallback(
    (_, original) => (
      <MenuColumnPropertiesTrayTrigger
        title={`Event Details — ${original.id}`}
        dataType={PropertiesTray.DataTypes.record}
        recordType={ContextTypes.alerts}
        value={original}
      />
    ),
    [],
  );

  const overrideColumns = useMemo(
    () =>
      columnsCollection({
        algorithms: algorithmsHash,
        categories: categoriesHash,
        cxActionMenu,
      }),
    [algorithmsHash, categoriesHash, cxActionMenu],
  );

  const collection = usePreparedColumns(ContextTypes.alerts, {
    overrideColumns,
    ignore: [/^ipinfo/, /^rules\./],
  });

  const preparedColumns = useMemo(() => Object.keys(collection), [collection]);

  const getColumns = useMemo(() => getColumnsHelper(collection), [collection]);

  const tableData = useMemo(() => {
    if (severities && !Object.values(severities).some(Boolean)) {
      return [];
    }

    return (tableDataProp || data || []).reduce((acc, item) => {
      if (severities && !severities[item.severity]) {
        return acc;
      }
      acc.push(item);
      return acc;
    }, []);
  }, [tableDataProp, data, severities]);

  const [tableColumns, available] = useAvailableExtractor({
    row: tableData?.[0],
    selectedColumns: columns,
    getColumns,
    preparedColumns,
    subtractedFields,
  });

  const algorithmsLength = Object.keys(algorithms || {}).length;
  useEffect(() => {
    if (!algorithmsLength && data?.length) {
      dispatch(ruleActions.fetchAlgorithms());
    }
  }, [algorithmsLength, data?.length]);

  useEffect(() => {
    if (!categories?.length && data?.length) {
      dispatch(ruleActions.fetchCategories());
    }
  }, [categories?.length, data?.length]);

  return (
    <Table
      data={tableData}
      noDataText={noDataText}
      columns={tableColumns}
      availableColumns={available}
      SubComponent={SubDetails}
      rowHeight={getRowHeight}
      {...tail}
    />
  );
};

const alertTableColumn = PropTypes.oneOf(Object.values(Columns || {}));

EventTable.propTypes = {
  noDataText: PropTypes.string,
  tableData: PropTypes.arrayOf(PropTypes.shape()),
  data: PropTypes.arrayOf(PropTypes.shape()),
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: alertTableColumn,
      desc: PropTypes.bool,
    }),
  ),
  columns: PropTypes.arrayOf(alertTableColumn),
  severities: PropTypes.shape(),
};

EventTable.defaultProps = {
  noDataText: 'No events to show',
  tableData: undefined,
  data: undefined,
  sortBy: defaultSortBy,
  columns: defaultColumns,
  severities: null,
};

export { EventTable, Columns };

export default EventTable;
