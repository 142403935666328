import PropTypes from '+prop-types';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-final-form';

import { useFlag } from '@unleash/proxy-client-react';
import capitalize from 'lodash.capitalize';
import isEqual from 'lodash.isequal';

import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';

import CheckBoxField from '+components/form/CheckBox';
import { Field, useFormState } from '+components/form/FinalForm';
import { FieldContainer, Group, Label } from '+components/form/FormField';
import Toggle from '+components/form/Toggle';
import { Col, Row } from '+components/Layout';

import FieldsSection from './FieldsSection';

const manageActionsArr = [
  PermissionModel.Actions.create,
  PermissionModel.Actions.update,
  PermissionModel.Actions.delete,
];

const RenderPermissions = (props) => {
  const { fields, resourceGroup, canManageResellerParams, disabled } = props;

  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true, modified: true },
  });

  const onManageToggle = useCallback(
    ({ name, resource }) =>
      (event) => {
        const canManage = event.target.checked;
        form.change(name, {
          resource,
          fetch: true,
          create: canManage,
          update: canManage,
          delete: canManage,
        });
      },
    [form],
  );

  useEffect(() => {
    formValues.permissions?.forEach((item, index) => {
      const { resource } = item;
      const resourceMeta = PermissionModel.Resources[resource];
      if (resourceMeta?.mergeWithResource) {
        const mergeWithItem = formValues.permissions?.find(
          (el) => el.resource === resourceMeta.mergeWithResource,
        );
        if (mergeWithItem) {
          const newItem = { ...mergeWithItem, resource };
          if (!isEqual(item, newItem)) {
            form.change(`permissions[${index}]`, newItem);
          }
        }
      }
    });
  }, [formValues.permissions]);

  return (
    <FieldsSection key={resourceGroup} label={resourceGroup}>
      {fields
        .map((name, index) => {
          const { resource, ...permissions } = fields.value[index];
          const resourceMeta = PermissionModel.Resources[resource];

          if (resourceMeta?.mergeWithResource) {
            return undefined;
          }

          if (resourceMeta?.group !== resourceGroup) {
            return undefined;
          }

          if (resourceMeta?.resellersOnly && !canManageResellerParams) {
            return undefined;
          }

          const { fetch, ...managePermissions } = permissions;
          const manage = Object.values(managePermissions).some(
            (item) => item === true,
          );

          let label = resourceMeta?.label || resource;

          if (isDnsEnabled) {
            switch (resource) {
              case PermissionModel.Resources.threat_model.value:
                label = label.replace('Network ', '');
                break;
              case PermissionModel.Resources.network_classification.value:
                label = label.replace('Network ', 'Traffic ');
                break;
              default:
                break;
            }
          }

          return (
            <Row key={resource} alignItems="center" wrap="nowrap">
              <Col container={false} xs item>
                <Group>
                  <Label disabled={disabled}>{label}</Label>
                  <FieldContainer>
                    <Toggle
                      checked={manage}
                      uncheckedLabel="Read-Only"
                      checkedLabel="Manage"
                      onChange={onManageToggle({ name, resource })}
                      disabled={disabled}
                      twoOptionToggle
                    />
                  </FieldContainer>
                </Group>
              </Col>

              {manage && (
                <Col container={false} xs item>
                  <Row wrap="nowrap" gap="42px" justifyContent="flex-end">
                    {manageActionsArr.map((action) => (
                      <Field
                        key={action}
                        // we need to use className to hide actions but reserve place for them in grid
                        className={
                          resourceMeta?.excludeActions?.includes(action)
                            ? 'hidden'
                            : ''
                        }
                        name={`${name}.${action}`}
                        type="checkbox"
                        component={CheckBoxField}
                        label={capitalize(action)}
                        disabled={disabled}
                      />
                    ))}
                  </Row>
                </Col>
              )}
            </Row>
          );
        })
        .sort((a, b) => (a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1))}
    </FieldsSection>
  );
};

RenderPermissions.propTypes = {
  fields: PropTypes.shape().isRequired,
  resourceGroup: PropTypes.string,
  disabled: PropTypes.bool,
  canManageResellerParams: PropTypes.bool,
};

RenderPermissions.defaultProps = {
  resourceGroup: '',
  disabled: false,
  canManageResellerParams: false,
};

export default RenderPermissions;
