import styled from 'styled-components';

const AdditionalFiltersRowItem = styled.div`
  position: relative;
  flex-shrink: ${({ $flexShrink }) => $flexShrink || 0};
  width: ${({ $width }) => $width || '210px'};

  display: flex;
  align-items: center;
  justify-content: stretch;

  .form__form-group {
    height: 100%;
  }
`;

export default AdditionalFiltersRowItem;
