/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import ArrowTopLeftIcon from 'mdi-react/ArrowTopLeftIcon';
import ClockIcon from 'mdi-react/ClockIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import RoutesIcon from 'mdi-react/RoutesIcon';
import TextBoxIcon from 'mdi-react/TextBoxIcon';
import TrafficLightOutlineIcon from 'mdi-react/TrafficLightOutlineIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as eventsActions,
  selectors as eventsSelectors,
} from '@/redux/api/events';
import { actions as actionsFilters } from '@/redux/globalFilters';

import {
  IntegrationGradientIcon,
  IntegrationIcon,
  TdmGradientIcon,
  TdmIcon,
  TrafficLightGradientIcon,
} from '@/shared/img/icon';

import { Breadcrumb } from '+components/Breadcrumb';
import Button, { ButtonVariants } from '+components/Button';
import Collapsible from '+components/Collapsible';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import {
  ActionsContainer,
  Col,
  LayoutSizes,
  LayoutTypes,
  Row,
} from '+components/Layout';
import * as Menu from '+components/Menu';
import { withMenu } from '+components/Menu';
import { usePageTabs } from '+components/PageTabs';
import RecordModal from '+components/RecordModal';
import Table from '+components/Table';
import {
  CellAlgorithmName,
  CellPluginName,
  CellResponsePolicyName,
  CellTrafficSourceName,
  UniversalCell,
} from '+components/Table/Cells';
import SubAccountTag from '+components/Tag/SubAccountTag';
import Tooltip from '+components/Tooltip';
import UniversalField from '+components/UniversalField';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import useUIProperty from '+hooks/useUIProperty';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import getNqlFieldName from '+utils/getNqlFieldName';
import { getSearchUrl } from '+utils/getSearchUrl';
import makeArr from '+utils/makeArr';

import AlgorithmDetails from './components/AlgorithmDetails';
import { getColumns } from './components/columns';
import ThresholdCharts from './components/ThresholdCharts';
import useCreateEventDashboard from './useCreateEventDashboard';

const excludeContexts = new Set([
  ContextTypes.blocks,
  ContextTypes.traffic,
  ContextTypes.dns,
  ContextTypes.flow,
]);

const EventDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = useParams();
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const [, , pageTabMethods] = usePageTabs();

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.alerts,
  });

  const permissions = usePermissions(PermissionModel.Resources.dashboard.value);
  const [filters] = useGlobalFilters();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const devices = useSelector(deviceSelectors.getDevices);
  const isDevicesFetching = useSelector(deviceSelectors.isFetching);
  const event = useSelector(eventsSelectors.getRecord(id)) || {};
  const isEventsFetching = useSelector(eventsSelectors.isFetchingRecord(id));

  const [recordModalOpened, setRecordModalOpened] = useState(false);

  useLoadingIndicator(isEventsFetching || isDevicesFetching);

  const isSubAccountRecord =
    event?.customer && event?.customer !== customer?.shortname;

  const columns = useMemo(
    () => getColumns({ labelContext: filters.labelContext }),
    [filters.labelContext],
  );

  const row = useMemo(() => ({ original: event }), [event]);

  const ipIntellData = useMemo(
    () =>
      (event?.ipinfo || []).map((item) => ({
        ...item,
        ipname:
          !filters.labelContext.show || filters.labelContext.ip !== 'name'
            ? []
            : item.ipname,
        customer: event?.customer,
      })),
    [event],
  );

  const fixedDevices = useMemo(
    () =>
      Object.values(devices || {}).reduce(
        (acc, el) => ({ ...acc, [el.name]: el.id }),
        {},
      ),
    [devices],
  );

  const onSearch = useCallback(() => {
    const { nql, from, to, endIsNow } = getFlowDataFromRecord({
      record: event,
      type: context,
    });
    const url = getSearchUrl({
      context: event.traffic_type,
      from,
      to: endIsNow ? 'now' : to,
      nql,
      customer: isSubAccountRecord ? event?.customer : undefined,
    });
    pageTabMethods.add(url);
  }, [event, isSubAccountRecord, context]);

  const onPushToGF = useCallback(() => {
    const { nql, from, to, endIsNow } = getFlowDataFromRecord({
      record: event,
      type: context,
    });
    dispatch(
      actionsFilters.changeFilter({
        period: {
          type: CustomType,
        },
        from,
        to,
        startIsMin: false,
        endIsNow,
        context: event.traffic_type,
        [getNqlFieldName(event.traffic_type)]: makeArr(nql),
        ...(isSubAccountRecord && { customers: [event?.customer] }),
      }),
    );
  }, [event, isSubAccountRecord, context]);

  useEffect(() => {
    if (isSubAccountRecord || Object.keys(devices || {}).length) {
      return;
    }
    dispatch(deviceActions.devicesFetch());
  }, [isSubAccountRecord, devices]);

  useEffect(() => {
    if (!id) {
      return undefined;
    }
    const namespace = `eventDetails_fetchEvent_${id}`;
    const search = new URLSearchParams(location.search);
    dispatch(
      eventsActions.fetch({ id, customer: search.get('customer') }, namespace),
    );
    return () => {
      dispatch(eventsActions.cancel(namespace));
    };
  }, [id, location.search]);

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    const rootPath = location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/'),
    );
    setMasqueradeUrl(rootPath);
    return () => {
      setMasqueradeUrl(null);
    };
  }, [location.pathname]);

  const [onCreateDashboard, dashboardConfirmModal, isReady] =
    useCreateEventDashboard(event);

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  if (!event?.id) {
    return (
      <Fragment>
        <Breadcrumb title="Event Details" />

        <GlobalFiltersSetting
          context={context}
          excludeContexts={excludeContextsArr}
          range={false}
          from={false}
          to={false}
          nql={false}
          customers={false}
          metric={false}
          socketControl={false}
        />

        <Col
          $type={LayoutTypes.card}
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          {isEventsFetching ? 'Loading...' : 'Event not found'}
        </Col>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Menu.TriggerMenu />

      <Breadcrumb title="Event Details" />

      <GlobalFiltersSetting
        context={context}
        excludeContexts={excludeContextsArr}
        range={false}
        from={false}
        to={false}
        nql={false}
        customers={false}
        metric={false}
        socketControl={false}
      />

      <ActionsContainer>
        <Tooltip title="Create a dashboard based on the date and nql from this event. An event template dashboard must be configured.">
          <div>
            <Button
              variant={ButtonVariants.text}
              startIcon={<ViewGridIcon size={16} />}
              onClick={onCreateDashboard}
              disabled={!event?.id || !isReady || !permissions?.create}
            >
              Create Dashboard
            </Button>
          </div>
        </Tooltip>

        <Button
          variant={ButtonVariants.text}
          onClick={() => setRecordModalOpened(true)}
        >
          View Raw Record
        </Button>

        <Button
          variant={ButtonVariants.text}
          startIcon={<MagnifyIcon size={16} />}
          onClick={onSearch}
        >
          Search {event?.traffic_type === 'flow' ? 'Flows' : 'DNS'}
        </Button>

        <Tooltip title="Push alert start/end and search NQL (if available) to the Global Filters">
          <div>
            <Button
              variant={ButtonVariants.text}
              startIcon={<ArrowTopLeftIcon size={16} />}
              onClick={onPushToGF}
            >
              Push to Global Filters
            </Button>
          </div>
        </Tooltip>
      </ActionsContainer>

      <Col gap={LayoutSizes.groupGap}>
        <Row
          gap={LayoutSizes.groupGap}
          wrap="nowrap"
          alignItems="center"
          $type={LayoutTypes.card}
        >
          <Row $type={LayoutTypes.field}>
            {isSubAccountRecord && (
              <Col container={false} marginRight="5px">
                <SubAccountTag
                  icon={<AccountMultipleIcon size={16} />}
                  context={event.customer}
                  clickable={false}
                />
              </Col>
            )}
            <Col container={false}>Event ID:</Col>
            <Col $type={LayoutTypes.fieldValue}>{event.id}</Col>
          </Row>
        </Row>

        <Row gap={LayoutSizes.groupGap}>
          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <TextBoxIcon size={16} />
              <span>Overview</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Severity:</Col>
              <Col $type={LayoutTypes.fieldValue} justifyContent="center">
                {UniversalCell('severity')({ row, value: event.severity })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Summary:</Col>
              <Col $type={LayoutTypes.fieldValue}>{event.summary}</Col>
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <ClockIcon size={16} />
              <span>Time</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Alert Type:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('alerttype')({ row, value: event.alerttype })}
              </Col>
            </Row>

            {event.duration === 0 ? (
              <Row $type={LayoutTypes.field}>
                <Col $type={LayoutTypes.fieldName}>Date:</Col>
                <Col $type={LayoutTypes.fieldValue}>
                  {UniversalCell('start')({ row, value: event.start })}
                </Col>
              </Row>
            ) : (
              <Fragment>
                <Row $type={LayoutTypes.field}>
                  <Col $type={LayoutTypes.fieldName}>Start:</Col>
                  <Col $type={LayoutTypes.fieldValue}>
                    {UniversalCell('start')({ row, value: event.start })}
                  </Col>
                </Row>

                <Row $type={LayoutTypes.field}>
                  <Col $type={LayoutTypes.fieldName}>End:</Col>
                  <Col $type={LayoutTypes.fieldValue}>
                    {UniversalCell('end')({ row, value: event.end })}
                  </Col>
                </Row>

                <Row $type={LayoutTypes.field}>
                  <Col $type={LayoutTypes.fieldName}>Duration:</Col>
                  <Col $type={LayoutTypes.fieldValue}>
                    {UniversalCell('duration')({ row, value: event.duration })}
                  </Col>
                </Row>
              </Fragment>
            )}
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <RoutesIcon size={16} />
              <span>Traffic</span>
            </Row>

            {isDnsEnabled && (
              <Row $type={LayoutTypes.field}>
                <Col $type={LayoutTypes.fieldName}>Type:</Col>
                <Col $type={LayoutTypes.fieldValue}>
                  <UniversalField
                    field="traffic_type"
                    value={event.traffic_type || ContextTypes.flow}
                    disabled
                  />
                </Col>
              </Row>
            )}

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>
                {isDnsEnabled ? 'Traffic' : 'Flow'} Sources:
              </Col>
              <Col $type={LayoutTypes.fieldValue}>
                {(event.datasrcs?.length
                  ? event.datasrcs
                  : event.flowsrcnames
                )?.map((name) => {
                  const field = event.datasrcs?.length
                    ? 'datasrcs'
                    : 'flowsrcnames';
                  if (fixedDevices[name]) {
                    return (
                      <CellTrafficSourceName
                        key={`device-${name}`}
                        field={field}
                        value={name}
                        id={fixedDevices[name]}
                        type="device"
                        original={event}
                      />
                    );
                  }
                  return (
                    <CellTrafficSourceName
                      key={`cloud-${name}`}
                      field={field}
                      value={name}
                      type="cloud"
                      original={event}
                    />
                  );
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}># of SRC IPs:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {(event.ipinfo || []).filter((item) => item.srcip).length}
              </Col>
            </Row>

            {event.traffic_type === 'flow' && (
              <Row $type={LayoutTypes.field}>
                <Col $type={LayoutTypes.fieldName}># of DST IPs:</Col>
                <Col $type={LayoutTypes.fieldValue}>
                  {(event.ipinfo || []).filter((item) => item.dstip).length}
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {(event?.search?.length || 0) < 2 && <ThresholdCharts event={event} />}

        <Row gap={LayoutSizes.groupGap}>
          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <TdmIcon size={16} />
              <span>Detection Model</span>
            </Row>

            {!!Object.keys(event || {}).length && (
              <AlgorithmDetails
                event={event}
                formatter={CellAlgorithmName}
                icon={TdmGradientIcon}
                isSubAccountRecord={isSubAccountRecord}
              />
            )}
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <TrafficLightOutlineIcon size={16} />
              <span>Response Policies</span>
            </Row>

            {(event.rules || []).map((item, index) => (
              <Collapsible
                key={`${item.name}-${index}`}
                {...item}
                formatter={CellResponsePolicyName}
                icon={TrafficLightGradientIcon}
                original={event}
              />
            ))}
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <IntegrationIcon size={16} />
              <span>Integrations</span>
            </Row>

            {(event.rules || [])
              .reduce((acc, item) => [...acc, ...item.plugins], [])
              .map((item, index) => (
                <Collapsible
                  key={`${item.name}-${index}`}
                  {...item}
                  formatter={CellPluginName}
                  icon={IntegrationGradientIcon}
                  original={event}
                />
              ))}
          </Col>
        </Row>

        <Row gap={LayoutSizes.groupGap} $type={LayoutTypes.card}>
          <Row $type={LayoutTypes.title}>
            <InformationIcon size={16} />
            <span>IP Intelligence</span>
          </Row>

          <Row>
            <Col sm={12} item container={false}>
              <Table
                id="Reports_Alerts_Detail_IpInfo"
                data={ipIntellData}
                columns={columns}
                minRows={1}
              />
            </Col>
          </Row>
        </Row>
      </Col>

      {recordModalOpened && (
        <RecordModal
          title={`Event Record — ${event.id}`}
          data={event}
          onToggle={() => setRecordModalOpened(false)}
          isOpen
        />
      )}

      {dashboardConfirmModal}
    </Fragment>
  );
};

export default withMenu(EventDetails);
