/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from '+prop-types';
import { useEffect } from 'react';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import CheckIcon from 'mdi-react/CheckIcon';

import { Description } from './FormField/index';

const inlineBlockTransitionMixin = css`
  display: inline-block;
  vertical-align: middle;
  //transition: all 0.3s;
`;

// Custom checkbox box to replace native input visual
const CheckboxCustom = styled((props) => (
  <span {...props}>
    <CheckIcon />
  </span>
))`
  ${({ theme, $color }) => css`
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: ${theme.colorFieldBackground};
    border: 1px solid ${theme.checkboxBorderColor};

    svg {
      // transition: all 0.3s;
      opacity: 0;
      height: 16px;
      width: 16px;
      fill: ${theme.checkboxColor};
    }

    ${$color
      ? css`
          background: ${$color};
          border-color: ${$color};
        `
      : ''}
    ${inlineBlockTransitionMixin}
  `}
`;

// Hidden input for actual checkbox state
const CheckboxHidden = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`;

// Text adjacent to checkbox box
const LabelText = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    overflow: hidden;
    line-height: 1.3;
    padding-top: 0;
    color: ${theme.colorText};
  `}
`;

// HTML label wrapper for checkbox
const Label = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: fit-content;
    position: relative;

    &:not(.disabled) {
      &:hover ${CheckboxCustom}, ${CheckboxHidden}:focus ~ ${CheckboxCustom} {
        border-color: ${theme.checkboxFocusBorderColor};
      }

      &:hover ${LabelText}, ${CheckboxHidden}:focus ~ ${LabelText} {
        color: ${theme.checkboxFocusBorderColor};
      }
    }

    &.readOnly {
      cursor: default;
      span {
        background-color: unset;
      }
      input {
        opacity: 0 !important;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.25;
      input {
        opacity: 0 !important;
      }
    }

    &.checked {
      ${CheckboxCustom} svg {
        opacity: 1;
      }
    }
  `}
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const CheckBox = (props) => {
  const {
    disabled,
    readOnly,
    className,
    name,
    value,
    checked,
    onChange,
    label,
    color,
    helperText,
    defaultChecked,
  } = props;

  // FIXME:
  //  For some reason, the input checked value is always true and doesn't update
  //  As a workaround, we'll use the input value as class name
  const localChecked = !!(value || checked);

  useEffect(() => {
    if (defaultChecked != null) {
      onChange(defaultChecked);
    }
  }, [defaultChecked, onChange]);

  return (
    <Label
      className={classNames(className, 'checkbox', {
        disabled,
        readOnly,
        checked: localChecked,
      })}
    >
      <CheckboxHidden
        name={name}
        onChange={onChange}
        checked={localChecked}
        disabled={disabled || readOnly}
      />
      <CheckboxContainer>
        <CheckboxCustom $color={color} />
        {(label || helperText) && (
          <div>{label && <LabelText>{label}</LabelText>}</div>
        )}
      </CheckboxContainer>
      {helperText && (
        <Description style={{ marginLeft: 0 }}>{helperText}</Description>
      )}
    </Label>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CheckBox.defaultProps = {
  label: '',
  name: '',
  defaultChecked: undefined,
  value: false,
  checked: false,
  disabled: false,
  readOnly: false,
  className: '',
  color: '',
  helperText: '',
};

const CheckBoxField = (props) => {
  const {
    input,
    label,
    defaultChecked,
    disabled,
    readOnly,
    className,
    color,
    helperText,
  } = props;

  return (
    <CheckBox
      {...input}
      label={label}
      defaultChecked={defaultChecked}
      disabled={disabled}
      readOnly={readOnly}
      className={className}
      color={color}
      helperText={helperText}
    />
  );
};

CheckBoxField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};

CheckBoxField.defaultProps = {
  label: '',
  helperText: '',
  defaultChecked: undefined,
  disabled: false,
  readOnly: false,
  className: '',
  color: '',
};

export { CheckBox };
export default CheckBoxField;
