import PropTypes from '+prop-types';
import { useToggle } from 'react-use';

import classNames from 'classnames';
import styled from 'styled-components';

import { Button } from '@mui/material';

const ShowButton = styled(Button)`
  color: ${({ theme }) => theme.tabActiveTextColor};
  background-color: ${({ theme }) => theme.tabActiveBackground};
  border-color: ${({ theme }) => theme.tabActiveBackground};
  height: 16px;
  border-radius: 8px;
  min-width: 42px;
  margin-left: 6px;
  &:hover {
    color: ${({ theme }) => theme.tabHoverTextColor};
    border-color: ${({ theme }) => theme.tabHoverBorderColor};
  }
`;

const FieldsSection = styled(
  ({
    className,
    label,
    children,
    collapsible,
    formLabelMargin,
    boldLabel,
    ...tail
  }) => {
    const [collapsed, toggleCollapsed] = useToggle(collapsible);
    return (
      <div {...tail} className={classNames(className, 'fields-section')}>
        <div className="fields-section-header-container">
          <span className="fields-section-label">{label}</span>
          <span className="fields-section-line" />
          {collapsible && (
            <ShowButton onClick={toggleCollapsed}>
              {collapsed ? 'SHOW' : 'HIDE'}
            </ShowButton>
          )}
        </div>
        {!collapsed && children}
      </div>
    );
  },
)`
  display: flex;
  flex-direction: column;
  margin: 15px 0 5px 0;

  .fields-section-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .fields-section-label {
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-left ${({ formLabelMargin }) => (formLabelMargin ? '140px' : '0')};
    font-weight ${({ boldLabel }) => (boldLabel ? '600' : '100')};

  }

  .fields-section-line {
    content: '';
    flex: 1;
    margin-left: 10px;
    height: 1px;
    background-color: ${({ theme }) => theme.fieldsGroupLabelColor};
  }
`;

FieldsSection.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Group label.
   */
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Whether or not the section can be collapsed to hide its child fields
   */
  collapsible: PropTypes.bool,
  /**
   * Whether or not to use bolded font for the section label
   */
  boldLabel: PropTypes.bool,
  /**
   * Whether or not to use include a 140px margin on the label - for use in forms
   */
  formLabelMargin: PropTypes.bool,
  /**
   * Child elements.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

FieldsSection.defaultProps = {
  className: undefined,
  label: undefined,
  collapsible: false,
  children: undefined,
  boldLabel: false,
  formLabelMargin: false,
};

export default FieldsSection;
