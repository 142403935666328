import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import ConfirmModal from '+components/ConfirmModal';
import { Field } from '+components/form/FinalForm';
import Description from '+components/form/FormField/components/Description';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import {
  validateForNotOnlyNumbers,
  validateNumber,
  validateRequired,
  validateSampleRate,
} from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import UniversalField from '+components/UniversalField';
import useMaxSources from '+hooks/useMaxSources';
import usePermissions from '+hooks/usePermissions';
import useUIProperty from '+hooks/useUIProperty';

import AwsKinesisFields from './providerFields/AwsKinesisFields';
import AwsS3Fields from './providerFields/AwsS3Fields';
import AwsTgwFields from './providerFields/AwsTgwFields';
import AzureNsgFields from './providerFields/AzureNsgFields';
import AzureVNetFields from './providerFields/AzureVnetFields';
import GcpPubsup from './providerFields/GcpPubsubFields';
import IbmCosFields from './providerFields/IbmCosFields';
import OracleCosFields from './providerFields/OracleCosFields';
import { formatVpc, vpcUItoParams } from './utils';

const StyledFormWizard = styled(FormWizard)`
  @media screen and (min-width: 480px) {
    .form.form--horizontal .form__form-group-label {
      width: 220px;
    }
    .form.form--horizontal .form__form-group-field {
      width: calc(100% - 220px);
    }
    .form.form--horizontal .form__form-group-description,
    .form.form--horizontal .form__button-toolbar {
      margin-left: 240px;
    }
  }

  .form.form--horizontal .form__form-group-plaintext {
    width: calc(100% - 240px);
  }

  .wizard__actions {
    padding-left: 240px;
  }
`;

const unique = (arr) =>
  arr.reduce((acc, el) => (acc.includes(el) ? acc : [...acc, el]), []);

const VpcForm = (props) => {
  const { mode, initialValues, loading, updateCallback, createCallback } =
    props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDnsContext = initialValues.traffictype === ContextTypes.dns;
  const isDnsEnabled = useFlag(FeatureFlags.dns);

  const [isUnderCovered] = useUIProperty('underCover');
  const permissions = usePermissions(
    PermissionModel.Resources.cloud_provider.value,
  );
  const vpcTags = useSelector(vpcSelectors.getVpcTags);
  const { tags: deviceTags } = useSelector(deviceSelectors.getState);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { maxDownsample } = useMaxSources();

  const adapter = initialValues.flowtype
    ? `${initialValues.flowtype}/${initialValues.flowresource}`
    : null;

  const canManage = initialValues.id
    ? permissions?.update
    : permissions?.create;
  const canRemove = initialValues.id && permissions?.delete;
  const canManageDownsample = maxDownsample == null || isUnderCovered;

  const normalizedTags = useMemo(() => {
    const flowTags = [...(deviceTags || []), ...(vpcTags || [])];
    return unique(flowTags.map((row) => row?.tag).filter((tag) => !!tag));
  }, [deviceTags, vpcTags]);

  const providerFields = useMemo(() => {
    switch (adapter) {
      case 'aws/s3':
        if (initialValues.logtype === 'aws-tgw-flow-log') {
          return <AwsTgwFields disabled={!canManage} />;
        }
        return <AwsS3Fields disabled={!canManage} isDnsContext />;
      case 'aws/kinesis':
        return <AwsKinesisFields disabled={!canManage} />;
      case 'aws-tgw/s3':
        return <AwsTgwFields disabled={!canManage} isDnsContext />;
      case 'azure/blobstorage':
        if (initialValues.logtype === 'azure-vnet-flow-log') {
          return <AzureVNetFields disabled={!canManage} />;
        }
        return <AzureNsgFields disabled={!canManage} />;
      case 'azure-vnet/blobstorage':
        return <AzureVNetFields disabled={!canManage} />;
      case 'gcp/pubsub':
        return <GcpPubsup disabled={!canManage} />;
      case 'ibm/objectstorage':
        return <IbmCosFields disabled={!canManage} />;
      case 'oracle/objectstorage':
        return <OracleCosFields vpc={initialValues} disabled={!canManage} />;
      default:
        return null;
    }
  }, [adapter, initialValues, canManage]);

  const onSubmit = useCallback(
    (UIvalues) => {
      // converted flattenend UI object to nested API object
      const values = vpcUItoParams(UIvalues);
      if (mode === 'update') {
        updateCallback(values);
      } else {
        createCallback(values);
      }
    },
    [mode, updateCallback, createCallback],
  );

  const onCancel = useCallback(() => {
    navigate(`${RoutePaths.sources}/add`);
  }, []);

  const onDeleteModalToggle = useCallback(() => {
    setShowDeleteModal((prevValue) => !prevValue);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(vpcActions.vpcDelete(initialValues.id));
    onCancel();
  }, [initialValues, onCancel]);

  useEffect(() => {
    dispatch(vpcActions.fetchTags());
  }, []);

  useEffect(() => {
    dispatch(deviceActions.fetchTags());
  }, []);

  return (
    <Fragment>
      <StyledFormWizard
        initialValues={initialValues}
        title={mode === 'create' && 'Add Cloud Provider'}
        description={
          mode === 'create' && (
            <Fragment>
              {/* eslint-disable-next-line max-len */}
              {isDnsContext
                ? `Netography collects cloud DNS query logs from Amazon Web Services and Google Cloud that
              ingests query data directly into the Netography Security Platform.`
                : `Netography collects cloud flow logs from Amazon Web Services,
              Microsoft Azure, Google Cloud, IBM Cloud and Oracle Cloud that
              ingests flow data directly into the Netography Security Platform.`}
            </Fragment>
          )
        }
        image="/assets/graphic-cloud-providers.png"
        onSubmit={onSubmit}
        onCancel={onCancel}
        loading={loading}
        disabled={loading || !canManage}
        deleteButtonText="Delete Cloud Provider"
        onDelete={onDeleteModalToggle}
        deleteButtonHidden={!initialValues.id}
        deleteButtonDisabled={!canRemove}
      >
        <Step>
          <Group>
            <Label>Provider</Label>
            <Plaintext wrappedText={false}>
              {formatVpc(adapter)}
              {isDnsEnabled && (
                <UniversalField
                  field="algo_record_type"
                  value={initialValues?.traffictype || ContextTypes.flow}
                  disabled
                />
              )}
            </Plaintext>
          </Group>

          <Field
            name="name"
            label="Name"
            component={TextField}
            type="text"
            maxLength={255}
            autoComplete="new-password"
            validate={[validateForNotOnlyNumbers, validateRequired]}
            disabled={!canManage}
            required
          />

          <Group>
            <Field
              name="enabled"
              label="Polling"
              type="checkbox"
              checkedLabel="Enabled"
              uncheckedLabel="Disabled"
              uppercaseLabels={false}
              component={ToggleField}
              disabled={!canManage}
            />
            <Description>
              Whether to poll the Cloud Provider for samples.
            </Description>
          </Group>

          {adapter !== 'gcp/pubsub' && (
            <Field
              name="samplerate"
              label="Down Sample"
              component={TextField}
              type="number"
              min={1}
              max={65535}
              style={{ width: '200px' }}
              validate={[validateRequired, validateNumber, validateSampleRate]}
              helperText={`Allow 1 in N ${
                isDnsContext ? 'queries' : 'flows'
              } received to be processed and recorded.`}
              disabled={!canManage || !canManageDownsample}
              required
            />
          )}

          {!isDnsContext && (
            <Field
              name="tags"
              label="Tags"
              options={normalizedTags}
              component={MultiSelectField}
              validate={validateForNotOnlyNumbers}
              parse={normalizeMultiSelectValue}
              helperText={`One or many tags to assign to every ${
                isDnsContext ? 'query' : 'flow'
              } from this VPC.`}
              disabled={!canManage}
              allowCreate
            />
          )}

          {providerFields}
        </Step>
      </StyledFormWizard>

      {showDeleteModal && (
        <ConfirmModal
          item={initialValues?.name}
          onToggle={onDeleteModalToggle}
          onConfirm={onDelete}
          isOpen
        />
      )}
    </Fragment>
  );
};

VpcForm.propTypes = {
  createCallback: PropTypes.func,
  updateCallback: PropTypes.func,
  mode: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    enabled: PropTypes.bool,
    flowtype: PropTypes.string,
    flowresource: PropTypes.string,
    traffictype: PropTypes.string,
    logtype: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

VpcForm.defaultProps = {
  mode: 'create',
  createCallback: () => {},
  updateCallback: null, // null so we can switch the UI accordingly
  initialValues: {},
  loading: false,
};

export default VpcForm;
